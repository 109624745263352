<template>
  <div class="flex justify-content-between gap-2 pt-2">
    <CustomLabel class="w-full" :label="this.$t('placeholder.phase')" :input="projectPhase">
      <Dropdown class="w-full" v-model="projectPhase" :options="phaseOptions" optionLabel="Name"
                :class="v$.projectPhase.$error ? 'p-invalid' : ''" :placeholder="this.$t('placeholder.phase')"/>
    </CustomLabel>

    <CustomLabel class="w-full" :label="this.$t('responsible')" :input="responsible">
      <Dropdown class="w-full" v-model="responsible" :options="responsibles" optionLabel="Name" filter autoFilterFocus
                :class="v$.responsible.$error ? 'p-invalid' : ''" :placeholder="this.$t('placeholder.responsible')"/>
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 pt-2">
    <CustomLabel class="w-full" :label="this.$t('title')" :input="title">
      <InputText class="w-full" v-model="title" id="Title" :class="v$.title.$error ? 'p-invalid' : ''" :placeholder="this.$t('placeholder.title')" />
    </CustomLabel>

    <CustomLabel class="w-full" :label="this.$t('expiresAt')" :input="expiresAt">
      <Calendar class="w-full" :modelValue="new Date(expiresAt)" dateFormat="dd.mm.yy" showIcon hideOnDateTimeSelect @update:modelValue="setUnix($event)"/>
    </CustomLabel>
  </div>

  <div class="pt-2">
    <FileUpload ref="fileUpload" name="demo[]" :customUpload="true" @uploader="uploadFiles" :multiple="true"
                accept=".jpg,.jpeg,.png,.eml,.msg,.doc,.docx,.pdf,.ppt,.pptx,.txt,.xls,.xlsx" :maxFileSize="52000000">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
  </div>

  <div v-if="files.length > 0">
    <AttachmentMaterialTable :attachments="files" :show-delete-button="projectOpen" @delete-attachment="deleteFile" />
  </div>
</template>

<script>

import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import FileUpload from "primevue/fileupload";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import Uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";
import AttachmentMaterialTable from "@/components/global-components/attachment-material-table/AttachmentMaterialTable";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";
import MSGReader from '@kenjiuno/msgreader';
import sanitizeFileName from "../../../mixins/sanitize-file-name/sanitize-file-name";

export default {
  name: "AddTask",
  components:{CustomLabel, Dropdown, InputText, Calendar, FileUpload, AttachmentMaterialTable},
  mixins:[Uuidv4Generator, S3FileManager, sanitizeFileName],
  emits: ['closePopup'],
  data(){
    return{
      expiresAt:new Date().getTime(),
      files:[],
      id:this.createUuidv4(),
      projectPhase:null,
      responsible:null,
      title:null,
      emailContent:null,
    }
  },
  props:{
    members:Array,
    projectId:String,
    projectOpen:Boolean,
    taskTitle:String,
    visitReportId:String,
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      projectPhase: {required},
      responsible: {required},
      title: {required},
      expiresAt:{required},
    }
  },

  computed:{
    phaseOptions(){
      return this.$store.getters.projectPhases;
    },

    responsibles(){
      const members = [];
      for(let member of this.members){
        if(member.type && member.type === 'User'){
          members.push(member)
        }
      }

      return members;
    },

    domain(){
      return this.$store.getters.domainName;
    }
  },

  methods:{
    uploadFiles(event){
      for (let file of event.files){
        let fileName = this.getFileName(file.name);
        const fileType = this.getFileType(file.name);
        this.$store.commit('setLoading',true);
        let filePath = 'sales/leadsAndProjects/' + this.projectId + '/tasks/' + this.id + '/';
        // Check if it's an email file
        if (file.type.includes('message')) {
          // Wait for email content extraction asynchronously
          this.getEmailText(file).then(() => {
            fileName = ' ' + `${this.$t('date')}: ${this.emailContent.date}` + ' ' + fileName;
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
            this.s3UploadFile(file, filePath).then(() => {
              this.files.push({
                Name:fileName,
                Type:fileType,
                LastModified:new Date(file.lastModified).toLocaleString(),
                Size:(file.size / (1024*1024)).toFixed(2) + " MB",
                "Key": filePath,
              });
              
              this.$store.commit('setLoading',false);
            }).catch((err) => {
              console.log(err);
              this.$store.commit('setLoading',false);
            });
          });
        }else if (file.name.endsWith('.msg')) {
          this.readMsgFile(file).then((emailData) => {
            fileName = ' ' + `${this.$t('date')}: ${emailData.date}` + ' ' + fileName;
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
            this.s3UploadFile(file, filePath).then(() => {
              this.files.push({
                Name:fileName,
                Type:fileType,
                LastModified:new Date(file.lastModified).toLocaleString(),
                Size:(file.size / (1024*1024)).toFixed(2) + " MB",
                "Key": filePath,
              });
              this.$store.commit('setLoading',false);
            }).catch((err) => {
              console.log(err);
              this.$store.commit('setLoading',false);
            });
          });
         }else{
          fileName = this.sanitizeFileName(fileName);
          filePath = filePath + fileName.trim();
          this.s3UploadFile(file, filePath).then(() => {
            this.files.push({
              Name:fileName,
              Type:fileType,
              LastModified:new Date(file.lastModified).toLocaleString(),
              Size:(file.size / (1024*1024)).toFixed(2) + " MB",
              "Key": filePath,
            });

            this.$store.commit('setLoading',false);
          }).catch((err) => {
            console.log(err);
            this.$store.commit('setLoading',false);
          });
        }
      }
    },

    getEmailText(file){
      return new Promise((resolve) => {
        const reader = new FileReader();
        
        // Read the file as text or binary, depending on your email file format (.eml or .msg)
        reader.onload = (e) => {
          const emailContent = e.target.result;
          this.emailContent = this.parseEmailContent(emailContent);
          resolve();
        };
        
        // Read as text (suitable for .eml files)
        reader.readAsText(file);
      });
    },

    async readMsgFile(file) {
      return new Promise((resolve) => {
        const fileReader = new FileReader();

        // When the file is loaded, use msg-parser to parse it
        fileReader.onload = async (e) => {
          const arrayBuffer = e.target.result;

          try {
            const msgReader = new MSGReader(arrayBuffer);
            const fileData = msgReader.getFileData();

            const emailData = {
            date: fileData.creationTime.replace(/,/g, "") || 'Unknown',
          };

          resolve(emailData)

          } catch (err) {
            console.error('Error parsing .msg file:', err);
          }
        };

        // Read the file as an ArrayBuffer
        fileReader.readAsArrayBuffer(file);
      });
    },

    getEmailFromSubject(emailString){
      return emailString.match(/<([^>]+)>/)[1];
    },

    parseEmailContent(emailContent) {
      // Parsing .eml content (simple approach for text-based emails)
      const emailLines = emailContent.split("\n");

      let date = "";

      emailLines.forEach(line => {
        if (line.startsWith("Date:")) {
          date = line.replace("Date:", "").replace(/,/g, "").replace(/\s\+.*/, "").trim();
        }
      });

      return {
        date
      }
    },

    deleteFile(file){
      this.$confirm.require({
        header: this.$t('swal.deleteFileHeader'),
        message: this.$t('swal.deleteFileText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('deleteS3Object',file.Key).then(() => {
            this.files = this.files.filter(foundFile => foundFile.Key !== file.Key);
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.fileDeleted'), life: 3000 });
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    checkIfFilesNotUploaded(){
      if(this.$refs.fileUpload.files.length > 0) {
        this.$confirm.require({
          header: this.$t('swal.submitWithoutUploadingHeader'),
          message: this.$t('swal.submitWithoutUploadingText'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.submitEdit();
          },
          reject: () => {
            this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
          }
        });
      }else{
        this.submitEdit();
      }
    },

    submitEdit(){
      this.v$.$validate();
      if(!this.v$.$error) {
        const task = {
          Closed:0,
          Comments: [],
          Created: new Date().getTime(),
          Domain: this.domain,
          ExpiresAt: this.expiresAt,
          Id: this.id,
          Phase: this.projectPhase,
          ProjectId:this.projectId,
          Responsible: this.responsible,
          Status:'10',
          Title: this.title,
          VisitReportId:this.visitReportId ? this.visitReportId : "",
        };

        this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('swal.taskAdded'), life: 3000 });

        this.$store.dispatch('addTask',task).then(() => {
          this.$emit('close-popup');
        });
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('errorKeysNeeded') + this.getErrors(), life: 5000 });
      }
    },

    getErrors(){
      let errorList = "";
      for(let error of this.v$.$errors){
        if(errorList !== ""){
          errorList = errorList + ", ";
        }
        switch(error.$property){
          case "projectPhase":
            errorList = errorList + ' ' + this.$t('placeholder.phase');
            break;
          case "responsible":
            errorList = errorList + ' ' + this.$t('responsible');
            break;
          case "title":
            errorList = errorList + ' ' + this.$t('title')
            break;
          case "expiresAt":
            errorList = errorList + ' ' + this.$t('expiresAt');
            break;
        }
      }

      return errorList;
    },

    setUnix(event){
      this.expiresAt = event.getTime();
    }
  },
  mounted(){
    /**
     * If we have the prop taskTitle, we set title to the taskTitle.
     */
    if(this.taskTitle){
      this.title = this.taskTitle;
    }
  }
}
</script>

<style scoped>

</style>