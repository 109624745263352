<template>
  <div class="mt-2">
    <CustomLabel class="w-full" :label="this.$t('type')" :input="selectedCorrespondenceType">
      <Dropdown class="w-full" v-model="selectedCorrespondenceType" :class="v$.selectedCorrespondenceType.$error ? 'p-invalid' : ''"
                :options="correspondenceTypes" filter :placeholder="this.$t('placeholder.type')" autoFilterFocus />
    </CustomLabel>
  </div>
</template>

<script>
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import Dropdown from "primevue/dropdown";
import useVuelidate from "@vuelidate/core/dist";
import {required} from "@vuelidate/validators";
export default {
  name: "SelectCorrespondenceType",
  components:{CustomLabel, Dropdown},
  emits:['submitSelectCorrespondence'],
  data(){
    return{
      selectedCorrespondenceType:null,
      correspondenceTypes:[this.$t('company'),this.$t('person'),this.$t('contact')],
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      selectedCorrespondenceType: {required},
    }
  },

  methods:{
    selectCorrespondence(){
      this.$emit('submit-select-correspondence',this.selectedCorrespondenceType);
    }
  }
}
</script>

<style scoped>

</style>