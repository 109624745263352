<template>
  <div v-if="showPaymentsIcon && salesData.Payments && salesData.Payments.length > 0" class="mb-2 mt-2">
    <PrimeButton class="mr-2"
                 icon="pi pi-info-circle"
                 :label="this.$t('showPayments')"
                 outlined
                 severity="contrast"
                 @click="openPaymentsTable = true" />
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('title')"
                 :input="salesData.Title">
      <InputText class="w-full"
                 :disabled="disabled"
                 v-model="salesData.Title"
                 :class="v$.salesData.Title.$error ? 'p-invalid' : ''"
                 :placeholder="this.$t('placeholder.title')"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('responsible')"
                 :input="salesData.Responsible ? salesData.Responsible.Name : null">
      <Dropdown class="w-full"
                v-model="salesData.Responsible"
                :class="v$.salesData.Responsible.$error ? 'p-invalid' : ''"
                :options="responsibleOptions"
                optionLabel="Name"
                :disabled="disabled"
                filter
                :placeholder="this.$t('placeholder.responsible')"
                autoFilterFocus />
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('commissionNumber')"
                 :input="salesData.CommissionNumber">
      <InputText class="w-full"
                 v-model="salesData.CommissionNumber"
                 :disabled="disabled"
                 :class="v$.salesData.CommissionNumber.$error ? 'p-invalid' : ''"
                 :placeholder="this.$t('placeholder.commissionNumber')"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('commissionValue')"
                 :input="salesData.CommissionValue">
      <InputNumber class="w-full"
                 v-model="salesData.CommissionValue"
                 :disabled="disabled"
                 :minFractionDigits="2"
                 :maxFractionDigits="2"
                 @update:modelValue="calculateMissingAmount"
                 :placeholder="this.$t('placeholder.commissionValue')"/>
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('orderNumber')"
                 :input="salesData.OrderNumber">
      <InputText class="w-full"
                 v-model="salesData.OrderNumber"
                 :disabled="disabled"
                 :placeholder="this.$t('placeholder.orderNumber')"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('orderValue')"
                 :input="salesData.OrderValue">
      <InputNumber class="w-full"
                 v-model="salesData.OrderValue"
                 :disabled="disabled"
                 :minFractionDigits="2"
                 :maxFractionDigits="2"
                 :placeholder="this.$t('placeholder.orderValue')"/>
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('discountPercentage')"
                 :input="salesData.Discount">
      <InputNumber class="w-full"
                 v-model="salesData.Discount"
                 :minFractionDigits="2"
                 :maxFractionDigits="2"
                 :placeholder="this.$t('placeholder.discountPercentage')"
                 max="100"
                 />
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('referenceNumber')"
                 :input="salesData.ReferenceNumber">
      <InputText class="w-full"
                 v-model="salesData.ReferenceNumber"
                 :disabled="disabled"
                 :placeholder="this.$t('placeholder.referenceNumber')"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('offerValue')"
                 :input="salesData.OfferValue">
      <InputNumber class="w-full"
                 v-model="salesData.OfferValue"
                 :class="v$.salesData.OfferValue.$error ? 'p-invalid' : ''"
                 :disabled="disabled"
                 :minFractionDigits="2"
                 :maxFractionDigits="2"
                 :placeholder="this.$t('placeholder.offerValue')"/>
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('salesDate')"
                 :input="salesData.SalesDate">
      <Calendar class="w-full"
                :modelValue="new Date(Number(salesData.SalesDate))"
                dateFormat="dd.mm.yy"
                :disabled="disabled"
                showIcon hideOnDateTimeSelect
                @update:modelValue="setSalesUnix($event)"/>
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('deliveryDate')"
                 :input="salesData.DeliveryDate">
      <Calendar class="w-full"
                :modelValue="new Date(Number(salesData.DeliveryDate))"
                dateFormat="dd.mm.yy"
                :disabled="disabled"
                showIcon
                hideOnDateTimeSelect
                @update:modelValue="setDeliveryUnix($event)"/>
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-4">
    <CustomLabel class="w-full"
                 :label="this.$t('amountPaid')"
                 :input="currentPaymentEntry.AmountPaid || currentPaymentEntry.AmountPaid === 0">
      <InputNumber class="w-full"
                   v-model="currentPaymentEntry.AmountPaid"
                   @update:modelValue="setPayment"
                   :disabled="disabled"
                   :minFractionDigits="2"
                   :maxFractionDigits="2"
                   :placeholder="this.$t('placeholder.amountPaid')" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('missingAmount')"
                 :input="currentPaymentEntry.MissingAmount || currentPaymentEntry.MissingAmount === 0">
      <InputNumber class="w-full"
                   v-model="currentPaymentEntry.MissingAmount"
                   :disabled="true" />
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-4 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('paymentDate')"
                 :input="currentPaymentEntry.PaymentDateUnix || currentPaymentEntry.PaymentDateUnix === 0">
      <Calendar class="w-full"
                :modelValue="new Date(Number(currentPaymentEntry.PaymentDateUnix))"
                dateFormat="dd.mm.yy"
                :disabled="disabled"
                showIcon
                hideOnDateTimeSelect
                @update:modelValue="setPaymentDateUnix($event)"/>
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('paymentDeadline')"
                 :input="currentPaymentEntry.PaymentDeadlineUnix || currentPaymentEntry.PaymentDeadlineUnix === 0">
      <Calendar class="w-full"
                :modelValue="new Date(Number(currentPaymentEntry.PaymentDeadlineUnix))"
                dateFormat="dd.mm.yy"
                :disabled="disabled"
                showIcon
                hideOnDateTimeSelect
                @update:modelValue="setPaymentDeadlineUnix($event)"/>
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2">
    <ContactSection :company-id="companyId"
                    :is-mandatory="true"
                    :contact-options="contactOptions"
                    :disabled="disabled"
                    :contact-props="salesData.Contact"
                    :is-dropdown="true"
                    :show-load-button="true"
                    @contact-changed="setContact"
                    @get-contacts="getContactsByCompanyId"/>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('placeholder.visibleTo')"
                 :input="salesData.VisibleTo">
      <Dropdown class="w-full"
                v-model="salesData.VisibleTo"
                :options="visibleToOptions"
                :disabled="disabled"
                :class="v$.salesData.VisibleTo.$error ? 'p-invalid' : ''"
                :placeholder="this.$t('placeholder.visibleTo')"/>
    </CustomLabel>

    <MultiSelect v-if="salesData.VisibleTo === 'Members'"
                 class="w-full"
                 v-model="salesData.Members"
                 :options="members"
                 optionLabel="Name"
                 :disabled="disabled"
                 filter
                 :placeholder="this.$t('placeholder.selectMembers')"
                 autoFilterFocus/>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="'Equipment'"
                 :input="salesData.Equipment ? salesData.Equipment.Name : ''">
      <MultiSelect class="w-full"
                   v-model="salesData.Equipment"
                   :options="equipments"
                   optionLabel="Name"
                   :disabled="disabled"
                   filter
                   :placeholder="this.$t('placeholder.equipment')"
                   autoFilterFocus/>
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="$t('supplier')"
                 :input="saleSupplier">
      <Dropdown class="w-full"
                v-model="saleSupplier"
                :options="supplierOptions"
                optionLabel="Name"
                @update:modelValue="setSaleSupplier"
                :placeholder="$t('placeholder.supplier')" />
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="$t('competitionModel')"
                 :input="salesData.CompetitionModel">
      <InputText class="w-full"
                 v-model="salesData.CompetitionModel"
                 :disabled="disabled"
                 :placeholder="this.$t('placeholder.competitionModel')"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="$t('machineModel')"
                 :input="salesData.MachineModel">
      <InputText class="w-full"
                 v-model="salesData.MachineModel"
                 :disabled="disabled"
                 :placeholder="this.$t('placeholder.machineModel')"
                 maxlength="100"/>
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="$t('machineNumber')"
                 :input="salesData.MachineNumber">
      <InputText class="w-full"
                 v-model="salesData.MachineNumber"
                 :disabled="disabled"
                 :placeholder="this.$t('placeholder.machineNumber')"
                 maxlength="100"/>
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="$t('offer')"
                 :input="selectedOffer.Name">
      <Dropdown class="w-full"
                v-model="selectedOffer"
                :options="offerOptions"
                optionLabel="Name"
                :disabled="disabled"
                filter
                :placeholder="this.$t('placeholder.offer')"
                autoFilterFocus/>
    </CustomLabel>
  </div>

  <div>
    <CustomLabel class="w-full"
                 :label="$t('notes')"
                 :input="salesData.Notes">
      <InputText class="w-full"
                 v-model="salesData.Notes"
                 :disables="disabled"
                 :placeholder="this.$t('placeholder.notes')"
                 maxlength="200"/>
    </CustomLabel>
  </div>

  <Popup :header="this.$t('sales')"
         :visible="openPDFReport"
         styling="width:100%"
         :show-menu-button="true"
         :menu-options="salesReportMenuOptions"
         :position="'top'"
         @close-popup="openPDFReport = false">
    <VisitReport ref="visitReport"
                 :is-read-only="true"
                 :project-id="projectId"
                 :sales-id="salesData.Id"
                 :report-type="'verkaufsPDF'" />
  </Popup>

  <Popup :header="this.$t('payments')"
         :visible="openPaymentsTable"
         styling="width:60%"
         :modal="true"
         :position="'top'"
         @close-popup="openPaymentsTable = false">
    <SalesPaymentsTable :payments="salesData.Payments" />
  </Popup>
</template>

<script>
import useVuelidate from "@vuelidate/core/dist";
import {required} from "@vuelidate/validators/dist";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Calendar from "primevue/calendar";
import InputNumber from "primevue/inputnumber";
import PrimeButton from "primevue/button";
import ContactSection from "@/components/global-components/contact-section/ContactSection";
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import Popup from "@/components/global-components/popup/Popup";
import VisitReport from "@/components/reports/visit-report/VisitReport";
import SalesPaymentsTable from "@/components/sales/sales-payments-table/SalesPaymentsTable";

export default {
  name: "EditSale",
  components:{
    SalesPaymentsTable,
    InputText,
    Dropdown,
    MultiSelect,
    Calendar,
    InputNumber,
    PrimeButton,
    ContactSection,
    CustomLabel,
    Popup,
    VisitReport,
  },
  emits:['editSale'],
  props:{
    disabled:Boolean,
    members:Array,
    companyId:String,
    projectId:String,
    salesDataProp:Object,
    showPaymentsIcon:Boolean,
  },
  data(){
    return {
      openPDFReport:false,
      openPaymentsTable:false,
      salesData: JSON.parse(JSON.stringify(this.salesDataProp)),
      selectedOffer: {Name:'', Id:''},
      salesReportMenuOptions:[],
      contactOptions:[],
      supplierOptions:[],
      saleSupplier: {},
      currentPaymentEntry:{
        AmountPaid: 0,
        PaymentDateUnix: 0,
        MissingAmount: this.salesDataProp.CommissionValue,
        PaymentDeadlineUnix: 0,
      },
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      salesData: {
        Title: { required },
        Responsible: { required },
        CommissionNumber: { required },
        OfferValue: { required },
        VisibleTo: { required },
      },
    }
  },
  computed:{
    responsibleOptions(){
      return this.$store.getters.responsibles.map(responsible => ({Name:responsible.FriendlyName,Id:responsible.User, type: "User"}));
    },

    equipments(){
      const equipments = this.$store.getters.equipments;
      const changedEquipmentData = [];
      for(let equipment of equipments){
        if(equipment.CompanyId === this.companyId){
          changedEquipmentData.push({Name:equipment.EquipmentName,Id:equipment.Id});
        }
      }
      return changedEquipmentData;
    },

    currentUserData(){
      let currentUser = {};
      const responsibles = this.$store.getters.responsibles;
      for(let responsible of responsibles){
        if(responsible.User === this.$store.getters.username){
          currentUser = responsible;
          break;
        }
      }

      return currentUser
    },

    visibleToOptions(){
      return ['Self','Members','All'];
    },

    offerOptions(){
      const offers = [];
      const allOffers = this.$store.getters.offers;
      for(let offer of allOffers){
        if(offer.Status === '20'){
          if(offer.ProjectId === this.projectId) {
            offers.push({'Name':offer.Title, 'Id':offer.Id});
          }
        }
      }
      return offers;
    },
  },
  methods:{
    setSalesUnix(event){
      this.salesData.SalesDate = event.getTime();
    },

    setDeliveryUnix(event){
      this.salesData.DeliveryDate = event.getTime();
    },

    setSaleSupplier(event) {
      this.salesData.Supplier = event.Name;
      this.salesData.SupplierId = event.Id;
    },

    setContact(contact){
      this.salesData.Contact = contact;
    },

    calculateMissingAmount(event){
      this.salesData.CommissionValue = Number(event).toFixed(2);
      this.currentPaymentEntry.MissingAmount = Number(event).toFixed(2) - Number(this.currentPaymentEntry.AmountPaid).toFixed(2);
    },

    setPayment(amountPaid){
      this.currentPaymentEntry.AmountPaid = Number(amountPaid).toFixed(2);
      this.currentPaymentEntry.MissingAmount = Number(this.salesData.CommissionValue).toFixed(2) - Number(amountPaid).toFixed(2);
    },

    setPaymentDateUnix(event){
      this.currentPaymentEntry.PaymentDateUnix = event.getTime();
    },

    setPaymentDeadlineUnix(event){
      this.currentPaymentEntry.PaymentDeadlineUnix = event.getTime();
    },

    editSale(){
      if(this.salesData.VisibleTo === "Self") this.salesData.Members = [
        {
          Id: this.currentUserData.User,
          Name: this.currentUserData.FriendlyName,
          type:"User"
        }
      ];

      if(this.salesData.VisibleTo === "All") this.salesData.Members = this.members;

      this.v$.$validate();
      if(!this.v$.$error) {
        if (this.salesData.VisibleTo === 'Members') {
          const foundCreator = this.salesData.Members.filter(member => member.Id === this.$store.getters.username)[0];
          if (!foundCreator) this.pushCreatorIntoMembers();
        }

        if(this.salesData.Payments && this.salesData.Payments.length > 0){
          const lastPaymentIndex = this.salesData.Payments.length -1;
          if(this.currentPaymentEntry.AmountPaid !== Number(this.salesData.Payments[lastPaymentIndex].AmountPaid)){
            this.currentPaymentEntry.MissingAmount = Number(this.currentPaymentEntry.MissingAmount).toFixed(2);
            this.salesData.Payments.push(this.currentPaymentEntry);
          }
        }else{
          this.salesData.Payments = [];
          this.salesData.Payments.push(this.currentPaymentEntry);
        }

        this.salesData.OfferName = this.selectedOffer.Name;
        this.salesData.OfferId = this.selectedOffer.Id;

        if(this.salesData.Contact && this.salesData.Contact.Id) this.salesData.ContactId = this.salesData.Contact.Id;
        this.salesData.ResponsibleId = this.salesData.Responsible.Id;

        this.salesData.SalesDate = Number(this.salesData.SalesDate);
        this.salesData.DeliveryDate = Number(this.salesData.DeliveryDate);

        this.salesData.CommissionValue =  Number(this.salesData.CommissionValue).toFixed(2);
        this.salesData.OrderValue =  Number(this.salesData.OrderValue).toFixed(2);
        this.salesData.OfferValue =  Number(this.salesData.OfferValue).toFixed(2);
        this.salesData.Discount =  Number(this.salesData.Discount).toFixed(2);
        
        this.$toast.add({severity: 'success', summary: this.$t('confirmed'), detail: this.$t('swal.saleEdited'), life: 3000});

        this.$store.dispatch('editSale',this.salesData).then(() => {
          this.$emit('edit-sale',this.salesData);
        });
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('errorKeysNeeded') + this.getErrors(), life: 5000 });
      }
    },

    getErrors(){
      let errorList = "";
      for(let error of this.v$.$errors){
        if(errorList !== ""){
          errorList = errorList + ", ";
        }
        switch(error.$property){
          case "Title":
            errorList = errorList + ' ' + this.$t('title');
            break;
          case "Responsible":
            errorList = errorList + ' ' + this.$t('responsible');
            break;
          case "CommissionNumber":
            errorList = errorList + ' ' + this.$t('commissionNumber')
            break;
          case "OfferValue":
            errorList = errorList + ' ' + this.$t('offerValue');
            break;
          case "VisibleTo":
            errorList = errorList + ' ' + this.$t('placeholder.visibleTo');
            break;
          case "Name":
            errorList = errorList + ' ' + this.$t('contact');
            break;
        }
      }

      return errorList;
    },

    createPDF(){
      this.createSalesReportMenuOptions();
      this.openPDFReport = true;
    },

    showPayments(){
      this.openPaymentsTable = true;
    },

    setCurrentPaymentEntry(){
      if(this.salesData.Payments && this.salesData.Payments.length > 0){
        const lastIndex = this.salesData.Payments.length -1;
        this.currentPaymentEntry = JSON.parse(JSON.stringify(this.salesData.Payments[lastIndex]));
      }
    },

    createSalesReportMenuOptions(){
      const optionsMenu = []

      optionsMenu.push(
        { label: this.$t('pdfExport'), icon: 'pi pi-fw pi-check',
          command: () => {this.$refs.visitReport.pdfExport();}
        },
      )

      this.salesReportMenuOptions = optionsMenu;
    },

    getContactsByCompanyId(){
      this.$store.commit('setLoading',true);
      this.$store.dispatch("getRequest","getContactsByCompanyId&query=" + this.companyId).then(resp => {
        this.$store.commit('setLoading',false);
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.contactOptions = body.map(contact => ({ Name: contact.ContactName,
            Id:contact.ContactId,
            Email:contact.ContactEmail,
            Phone:contact.OptionalParams ? contact.OptionalParams.Mobile ? contact.OptionalParams.Mobile : "" : "",
            Landline:contact.OptionalParams ? contact.OptionalParams.Landline ? contact.OptionalParams.Landline : "" : ""}));
        }
      });
    },

    getSupplierOptions(){
      this.$store.dispatch("getRequest","getSuppliers").then(resp => {
        const body = JSON.parse(resp.body);
        this.supplierOptions = body.Items.map((item) => ({Name:item.Name, Id:item.Id}));
      });
    },
  },
  mounted(){
    this.selectedOffer.Name = this.salesData.OfferName;
    this.selectedOffer.Id = this.salesData.OfferId;
    this.setCurrentPaymentEntry();
    // Only get suppliers when we can edit them.
    if(!this.disabled) this.getSupplierOptions();

    this.saleSupplier = {Name: this.salesData.Supplier, Id: this.salesData.SupplierId};
  },

  watch: {
    salesDataProp(newVal) {
      if (newVal) {
        this.selectedOffer.Name = newVal.OfferName;
        this.selectedOffer.Id = newVal.OfferId;
        this.salesData = newVal;
      }
    },
  }
}
</script>

<style scoped>

</style>