<template>
  <div class="relative">
    <div v-if="input" style="top:-8px; font-size: 12px; z-index:1; left:5px;position:absolute;">
      <label class="small-margin-right">{{label}}</label>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CustomLabel",
  props:{
    label:String,
    input:[String, Number, Object],
  }
}
</script>

<style scoped>

</style>