<template>
  <div class="flex justify-content-between gap-2">
    <CustomLabel v-if="isDropdown" class="w-full" :label="this.$t('contact')" :input="contact">
      <Dropdown class="w-full" v-model="contact" :options="contactOptions" optionLabel="Name"
                :class="isMandatory && validationError ? 'p-invalid' : ''" :disabled="disabled"
                filter :placeholder="this.$t('placeholder.selectContact')" autoFilterFocus @change="emitContactChanged"/>
    </CustomLabel>

    <CustomLabel v-else  class="w-full" :label="this.$t('contact')" :input="contact.Name">
      <InputText class="w-full" v-model="contact.Name" :disabled="disabled"
                 :placeholder="this.$t('placeholder.contactName')" @change="emitContactChanged"/>
    </CustomLabel>
    
    <PrimeButton v-if="!disabled && showLoadButton" severity="secondary" icon="pi pi-sync" plain @click="emitGetContacts" />

    <PrimeButton v-if="!disabled" icon="pi pi-plus" severity="contrast" plain text @click="openAddContact" />
  </div>

  <div class="flex justify-content-between gap-2 mt-2">
    <CustomLabel class="w-full" :label="this.$t('email')" :input="contact.Email">
      <InputText class="w-full" v-model="contact.Email" :disabled="isDropdown" :placeholder="this.$t('placeholder.email')" @change="emitContactChanged"/>
    </CustomLabel>

    <CustomLabel class="w-full" :label="this.$t('phone')" :input="contact.Phone">
      <InputText class="w-full" v-model="contact.Phone" :disabled="isDropdown" :placeholder="this.$t('placeholder.phone')" @change="emitContactChanged"/>
    </CustomLabel>

    <CustomLabel class="w-full" :label="this.$t('landline')" :input="contact.Landline">
      <InputText class="w-full" v-model="contact.Landline" :disabled="isDropdown" :placeholder="this.$t('placeholder.landline')" @change="emitContactChanged"/>
    </CustomLabel>
  </div>

  <Popup :modal="true" :visible="creatingContact" :header="this.$t('createContact')" :styling="'width:50%'"
         :show-save-button="true" @button-clicked="submitAddContactPopup" @close-popup="creatingContact = false" >
    <AddContact ref="addContact" @close-popup="creatingContact = false" />
  </Popup>
</template>

<script>
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import PrimeButton from "primevue/button";
import Popup from "@/components/global-components/popup/Popup";
import AddContact from "@/components/contact/add-contact/AddContact";

export default {
  name: "ContactSection",
  components:{AddContact, Popup, CustomLabel, InputText, Dropdown, PrimeButton},
  emits: ['contactChanged', 'getContacts'],
  props:{
    contactProps:Object,
    contactOptions:Array,
    companyId:String,
    disabled:Boolean,
    isDropdown:Boolean,
    isMandatory: Boolean,
    showLoadButton:Boolean,
    validationError: String,
  },
  data(){
    return{
      contact: this.contactProps ? JSON.parse(JSON.stringify(this.contactProps)) : {Id:"",Name:"",Email:"",Phone:"",Landline:""},
      creatingContact:false,
    }
  },
  methods:{
    openAddContact(){
      this.creatingContact = true;
    },

    submitAddContactPopup(){
      this.$refs.addContact.addContact();
    },

    emitContactChanged(){
      this.$emit('contact-changed',this.contact);
    },

    emitGetContacts(){
      this.$emit('get-contacts');
    }
  },
  watch:{
    contactProps(){
      this.contact = this.contactProps ? JSON.parse(JSON.stringify(this.contactProps)) : {Id:"",Name:"",Email:"",Phone:"",Landline:""}
    }
  }
}
</script>

<style scoped>

</style>