<template>
  <div class="flex justify-content-between gap-2 pt-2">
    <CustomLabel class="w-full" :label="this.$t('placeholder.phase')" :input="task.Phase">
      <Dropdown class="w-full" :disabled="disabled" v-model="task.Phase" :options="phaseOptions" optionLabel="Name"
                :class="v$.task.Phase.$error ? 'p-invalid' : ''" :placeholder="this.$t('placeholder.phase')"/>
    </CustomLabel>

    <CustomLabel class="w-full" :label="this.$t('responsible')" :input="task.Responsible">
      <Dropdown class="w-full" :disabled="disabled" v-model="task.Responsible" :options="responsibles" optionLabel="Name"
                :class="v$.task.Responsible.$error ? 'p-invalid' : ''" filter :placeholder="this.$t('placeholder.responsible')" autoFilterFocus/>
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 pt-2">
    <CustomLabel class="w-full" :label="this.$t('title')" :input="task.Title">
      <InputText class="w-full" :disabled="disabled" v-model="task.Title" id="Title" :class="v$.task.Title.$error ? 'p-invalid' : ''" :placeholder="this.$t('placeholder.title')" />
    </CustomLabel>

    <CustomLabel class="w-full" :label="this.$t('expiresAt')" :input="task.ExpiresAt">
      <Calendar class="w-full" :disabled="disabled" :modelValue="new Date(Number(task.ExpiresAt))" dateFormat="dd.mm.yy" :class="v$.task.ExpiresAt.$error ? 'p-invalid' : ''"
                showIcon hideOnDateTimeSelect @update:modelValue="setUnix($event)"/>
    </CustomLabel>
  </div>

  <div v-if="projectOpen && !disabled" class="pt-2">
    <FileUpload ref="fileUpload" name="demo[]" :customUpload="true" @uploader="uploadFiles" :multiple="true" accept=".jpg,.jpeg,.png,.eml,.msg,.doc,.docx,.pdf,.ppt,.pptx,.txt,.xls,.xlsx" :maxFileSize="52000000">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
  </div>

  <div v-if="files.length > 0">
    <AttachmentMaterialTable :attachments="files" :show-delete-button="projectOpen" @delete-attachment="deleteFile" />
  </div>

  <div class="mt-4">
    <span class="font-bold">{{$t('taskComments')}} ({{task.Comments ? task.Comments.length : 0}})</span>
    <TaskComments :projectOpen="projectOpen" :task-open="task.Status === '10'" :task-comments="task.Comments" @add-comment="addComment" />
  </div>
</template>

<script>
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import FileUpload from "primevue/fileupload";
import TaskComments from "@/components/tasks/task-comments/TaskComments";
import AttachmentMaterialTable from "@/components/global-components/attachment-material-table/AttachmentMaterialTable";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";
import MSGReader from '@kenjiuno/msgreader';
import sanitizeFileName from "../../../mixins/sanitize-file-name/sanitize-file-name";

export default {
  name: "EditTask",
  components:{TaskComments, CustomLabel, AttachmentMaterialTable, Dropdown, InputText, Calendar, FileUpload},
  mixins:[S3FileManager, sanitizeFileName],
  emits: ['closePopup','reopenTask','closeTask'],
  props:{
    taskProp:Object,
    members:Array,
    projectId:String,
    projectOpen:Boolean,
    disabled:Boolean,
  },

  data(){
    return{
      files:[],
      task:JSON.parse(JSON.stringify(this.taskProp)),
      emailContent:null,
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      task:{
        ExpiresAt:{required},
        Responsible:{required},
        Phase:{required},
        Title:{required},
      }
    }
  },

  computed:{
    phaseOptions(){
      return this.$store.getters.projectPhases;
    },

    responsibles(){
      const members = [];
      for(let member of this.members){
        if(member.type && member.type === 'User'){
          members.push(member)
        }
      }

      return members;
    }
  },

  methods:{
    emitClosePopup(){
      this.$emit('close-popup');
    },

    uploadFiles(event){
      for (let file of event.files){
        let fileName = this.getFileName(file.name);
        const fileType = this.getFileType(file.name);
        this.$store.commit('setLoading',true);
        let filePath = 'sales/leadsAndProjects/' + this.projectId + '/tasks/' + this.task.Id + '/';
        // Check if it's an email file
        if (file.type.includes('message')) {
          // Wait for email content extraction asynchronously
          this.getEmailText(file).then(() => {
            fileName = ' ' + `${this.$t('date')}: ${this.emailContent.date}` + ' ' + fileName;
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
            this.s3UploadFile(file, filePath).then(() => {
              this.files.push({
                Name:fileName,
                Type:fileType,
                LastModified:new Date(file.lastModified).toLocaleString(),
                Size:(file.size / (1024*1024)).toFixed(2) + " MB",
                "Key": filePath,
              });
              
              this.$store.commit('setLoading',false);
            }).catch((err) => {
              console.log(err);
              this.$store.commit('setLoading',false);
            });
          });
        }else if (file.name.endsWith('.msg')) {
          this.readMsgFile(file).then((emailData) => {
            fileName = ' ' + `${this.$t('date')}: ${emailData.date}` + ' ' + fileName;
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
            this.s3UploadFile(file, filePath).then(() => {
              this.files.push({
                Name:fileName,
                Type:fileType,
                LastModified:new Date(file.lastModified).toLocaleString(),
                Size:(file.size / (1024*1024)).toFixed(2) + " MB",
                "Key": filePath,
              });
              this.$store.commit('setLoading',false);
            }).catch((err) => {
              console.log(err);
              this.$store.commit('setLoading',false);
            });
          });
         }else{
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
          this.s3UploadFile(file, filePath).then(() => {
            this.files.push({
              Name:fileName,
              Type:fileType,
              LastModified:new Date(file.lastModified).toLocaleString(),
              Size:(file.size / (1024*1024)).toFixed(2) + " MB",
              "Key": filePath,
            });

            this.$store.commit('setLoading',false);
          }).catch((err) => {
            console.log(err);
            this.$store.commit('setLoading',false);
          });
        }
      }
    },

    getEmailText(file){
      return new Promise((resolve) => {
        const reader = new FileReader();
        
        // Read the file as text or binary, depending on your email file format (.eml or .msg)
        reader.onload = (e) => {
          const emailContent = e.target.result;
          this.emailContent = this.parseEmailContent(emailContent);
          resolve();
        };
        
        // Read as text (suitable for .eml files)
        reader.readAsText(file);
      });
    },

    async readMsgFile(file) {
      return new Promise((resolve) => {
        const fileReader = new FileReader();

        // When the file is loaded, use msg-parser to parse it
        fileReader.onload = async (e) => {
          const arrayBuffer = e.target.result;

          try {
            const msgReader = new MSGReader(arrayBuffer);
            const fileData = msgReader.getFileData();

            const emailData = {
            date: fileData.creationTime.replace(/,/g, "") || 'Unknown',
          };

          resolve(emailData)

          } catch (err) {
            console.error('Error parsing .msg file:', err);
          }
        };

        // Read the file as an ArrayBuffer
        fileReader.readAsArrayBuffer(file);
      });
    },

    getEmailFromSubject(emailString){
      return emailString.match(/<([^>]+)>/)[1];
    },

    parseEmailContent(emailContent) {
      // Parsing .eml content (simple approach for text-based emails)
      const emailLines = emailContent.split("\n");

      let date = "";

      emailLines.forEach(line => {
        if (line.startsWith("Date:")) {
          date = line.replace("Date:", "").replace(/,/g, "").replace(/\s\+.*/, "").trim();
        }
      });

      return {
        date
      }
    },

    deleteFile(file){
      this.$confirm.require({
        header: this.$t('swal.deleteFileHeader'),
        message: this.$t('swal.deleteFileText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('deleteS3Object',file.Key).then(() => {
            this.files = this.files.filter(foundFile => foundFile.Key !== file.Key);
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.fileDeleted'), life: 3000 });
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    checkIfFilesNotUploaded(){
      if(this.$refs.fileUpload.files.length > 0) {
        this.$confirm.require({
          header: this.$t('swal.submitWithoutUploadingHeader'),
          message: this.$t('swal.submitWithoutUploadingText'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.submitEdit();
          },
          reject: () => {
            this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
          }
        });
      }else{
        this.submitEdit();
      }
    },

    submitEdit(){
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.taskEdited'), life: 3000 });
        this.$store.dispatch('editTask', this.task).then(() => {
          this.emitClosePopup();
        });
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('errorKeysNeeded') + this.getErrors(), life: 5000 });
      }
    },

    getErrors(){
      let errorList = "";
      for(let error of this.v$.$errors){
        if(errorList !== ""){
          errorList = errorList + ", ";
        }
        switch(error.$property){
          case "Phase":
            errorList = errorList + ' ' + this.$t('placeholder.phase');
            break;
          case "Responsible":
            errorList = errorList + ' ' + this.$t('responsible');
            break;
          case "Title":
            errorList = errorList + ' ' + this.$t('title')
            break;
          case "ExpiresAt":
            errorList = errorList + ' ' + this.$t('expiresAt');
            break;
        }
      }

      return errorList;
    },

    addComment(comment){
      const task = {...this.task};
      task.Comments.push(comment);
      this.$store.dispatch('editTask',task).then(() => {
        this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.taskCommentAdded'), life: 3000 });
      });
    },

    reopenTask(){
      this.$emit('reopen-task',this.task);
    },

    closeTask(){
      this.$emit('close-task',this.task);
    },

    setUnix(event){
      this.task.ExpiresAt = event.getTime();
    },
  },

  mounted(){
    this.getS3Objects('sales/leadsAndProjects/' + this.projectId + '/tasks/' + this.task.Id + '/').then(res => {
      this.files = res;
    });
  },
  watch: {
    taskProp(newVal) {
      if (newVal) {
        this.task = newVal;
      }
    },
  }
}
</script>

<style scoped>

</style>