<template>
  <div class="flex justify-content-center">
    <div class="xl:mt-8 xl:mb-6" style="width:500px">
      <div class=" xl:p-8 p-2 box-shadow">
        <div class="m-auto w-10rem mb-5">
          <img :src="require('@/assets/4sales-Logo.svg')" alt="">
        </div>
        <div>
          <CustomLabel class="w-full mb-4" label="Domain Id" :input="domainId">
            <InputText class="w-full" v-model="domainId"  :class="v$.domainId.$error ? 'p-invalid' : ''"
                       :placeholder="$t('placeholder.domainId')" />
          </CustomLabel>
          <CustomLabel class="w-full mb-4" :label="$t('username')" :input="userName">
            <InputText class="w-full" v-model="userName"  :class="v$.userName.$error ? 'p-invalid' : ''"
                       :placeholder="$t('placeholder.username')" />
          </CustomLabel>
          <CustomLabel class="w-full mb-4" :label="$t('password')" :input="password">
            <InputText class="w-full" type="password" v-model="password"  :class="v$.password.$error ? 'p-invalid' : ''"
                       :placeholder="$t('placeholder.password')" v-on:keyup.enter="attemptLogin" />
          </CustomLabel>
          <div class="mt-4">
            <a class="text-sm cursor-pointer" @click="forgotPassword">{{$t('resetPassword')}}</a>
          </div>
        </div>

        <div class="text-sm mt-4 mb-4">
          <span>{{ $t('termsAndPrivacy')}}</span>
          <div style="margin-top: 5px">
            <a class="impLink" target="_blank" href="https://www.codevance.de/data-privacy">{{ $t('learnMore')}}</a>
          </div>
        </div>

        <div class="mt-4">
          <PrimeButton class="w-full" label="Login" severity="contrast" small @click="attemptLogin" />
        </div>
      </div>
    </div>
    <div style="position: fixed; bottom: 20px" class="flex mt-2 justify-content-between">
      <div class="flex gap-2 justify-content-end text-sm">
        <div>
          <SelectLanguage />
        </div>
        <a href="https://www.codevance.de/contact" target="_blank">{{$t('contactUs')}}</a>
        <a target="_blank" href="https://www.codevance.de/impressum">{{$t('terms')}} </a>
        <a target="_blank" href="https://www.codevance.de/data-privacy">{{$t('privacy')}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import PrimeButton from "primevue/button";
import InputText from "primevue/inputtext";
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import SelectLanguage from "@/components/global-components/select-language/SelectLanguage";
import { Auth } from 'aws-amplify';
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import getStoreData from "@/mixins/get-store-data/getStoreData";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'login-component',
  components: {
    SelectLanguage, PrimeButton, InputText, CustomLabel
  },
  mixins:[getStoreData,S3FileManager],
  data(){
    return {
      logo: '',
      userName: "",
      password: "",
      domainId: "",
      showMfaPopup: false,
      user: null,
      mfaKey: "",
      qrCode: "",
      lang: ""
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      userName:{required},
      password:{required},
      domainId:{required}
    }
  },
  methods: {
    attemptLogin(){
    this.v$.$validate();
      if (!this.v$.$error) {
        this.signIn();
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.provideLogin'), life: 3000 });
      }
    },

    async signIn() {
      this.$store.commit('setLoading', false); // In case the loader is somehow set to true.
      try {
        Auth.signIn(this.domainId + "_" + this.userName, this.password).then(user => {
          this.user = user;
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('authenticationHintPassword'), life: 3000 });
          } else if (user.challengeName === "MFA_SETUP") {
            this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('authenticationHintMFA'), life: 3000 });
          } else if (user.challengeName === "SOFTWARE_TOKEN_MFA"){
            this.$swal({
              title: "MFA Token",
              text: this.$t("enterMfaToken"),
              input: 'text',
              showCancelButton: true,
              cancelButtonColor: "#aaa",
              cancelButtonText: this.$t('cancel'),
            }).then((result) => {
              if(result.value){
                Auth.confirmSignIn(user,result.value,"SOFTWARE_TOKEN_MFA").then(()=>{
                  this.$store.commit('setLoading',true);
                  this.$store.dispatch('getCredentials').then(() => {
                    this.tryToSetStoreAndRoute();
                  })
                },()=>{
                  this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('codeDoesntMatch'), life: 3000 });
                  return this.signIn();
                });
              }
            });
          } else {
            // if we are already logged in
            Auth.currentAuthenticatedUser().then(res => {
              if (res) {
                this.$store.dispatch('getCredentials').then(() => {
                  this.tryToSetStoreAndRoute();
                })
              }
            })
          }
        }).catch(e => {
          this.$store.commit('setLoading',false);
          this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: e.message, life: 3000 });
        });
      } catch (error) {
        console.log('error signing in', error);
      }
    },
    newPasswordRequired(user){
      return new Promise((resolve)=>{
        this.$swal({
          title: this.$t('newPasswordRequired'),
          input: 'password',
          inputPlaceholder: 'Enter your password',
          inputAttributes: {
            autocapitalize: 'off',
            autocorrect: 'off'
          }
        }).then((password) => {
          Auth.completeNewPassword(
              user,               // the Cognito User Object
              password,       // the new password
          ).then(() => {
            resolve();
          }).catch(e => {
            console.log(e);
          });
        });
      });
    },

    forgotPassword(){
      if (typeof window !== 'undefined') {
        window.open("https://auth.4service.io");
      }
    },

    tryToSetStoreAndRoute(){
      this.$store.dispatch('getRequest',"hasSalesLicense").then((data) => {
        if (data.body === "true") {
          this.getStoreData().then(() => {
            this.configureS3WithStorage();
            this.$router.push("/");
          });
        }else{
          Auth.signOut();
          this.$store.dispatch('signout');
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.noSalesLicense'), life: 3000 });
          this.$store.commit('setLoading',false);
        }
      });
    },

  },
  mounted() {
    const lang = localStorage.getItem("lang");
    this.$i18n.locale = lang && lang !== "" ? lang : "de";
    this.$store.commit('setLoading', false);
  }


}
</script>

<style scoped>

</style>