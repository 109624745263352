<template>
  <div class="mb-2 mt-2"
       :class="isDesktop ? 'flex justify-content-between gap-2' : ''">
    <CustomLabel class="w-full"
                 :class="isDesktop ? '' : 'mb-2'"
                 :label="this.$t('displayName')"
                 :input="contactData.contactName">
      <InputText class="w-full"
                 v-model="contactData.contactName"
                 :class="v$.contactData.contactName.$error ? 'p-invalid' : ''"
                 :placeholder="this.$t('placeholder.displayName')"
                 maxlength="100" />
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2"
       :class="isDesktop ? 'flex justify-content-between gap-2' : ''">
    <CustomLabel class="w-full"
                 :class="isDesktop ? '' : 'mb-2'"
                 :label="this.$t('company')"
                 :input="company ? company.Name : null">
      <Dropdown class="w-full"
                v-model="company"
                :class="v$.company.$error ? 'p-invalid' : ''"
                :options="companyOptions"
                optionLabel="Name"
                filter
                :placeholder="this.$t('placeholder.company')"
                autoFilterFocus
                @update:modelValue="setCompany"
                @filter="searchForCompanies"/>
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('email')"
                 :input="contactData.contactEmail">
      <InputText class="w-full"
                 v-model="contactData.contactEmail"
                 :class="v$.contactData.contactEmail.$error ? 'p-invalid' : ''"
                 :placeholder="this.$t('placeholder.email')"
                 maxlength="100" />
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2"
       :class="isDesktop ? 'flex justify-content-between gap-2' : ''">
    <CustomLabel class="w-full"
                 :class="isDesktop ? '' : 'mb-2'"
                 :label="this.$t('firstName')"
                 :input="contactData.optionalParams.FirstName">
      <InputText class="w-full"
                 v-model="contactData.optionalParams.FirstName"
                 :placeholder="this.$t('placeholder.firstName')"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('lastName')"
                 :input="contactData.optionalParams.LastName">
      <InputText class="w-full"
                 v-model="contactData.optionalParams.LastName"
                 :class="v$.contactData.optionalParams.$error ? 'p-invalid' : ''"
                 :placeholder="this.$t('placeholder.lastName')"
                 maxlength="100" />
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2"
       :class="isDesktop ? 'flex justify-content-between gap-2' : ''">
    <CustomLabel class="w-full"
                 :class="isDesktop ? '' : 'mb-2'"
                 :label="this.$t('classification')"
                 :input="selectedTagContainer">
      <Dropdown class="w-full"
                v-model="selectedTagContainer"
                :class="v$.selectedTagContainer.$error ? 'p-invalid' : ''"
                :options="tagContainers"
                optionLabel="ContainerName"
                filter
                :placeholder="this.$t('classification')"
                autoFilterFocus />
    </CustomLabel>
  </div>

  <div class="w-full">
    <div v-for="(customList) of customLists"
         class="mb-2 mt-2"
         :class="isDesktop ? 'flex justify-content-between gap-2' : ''"
         :key="customList.Id">
      <div v-if="customList && customList.FieldParams.IsInputField"
           class="w-full">
        <CustomLabel class="w-full"
                     :class="isDesktop ? '' : 'mb-2'"
                     :label="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn"
                     :input="Tags[customList.Id]">
          <InputText class="w-full"
                     v-model="Tags[customList.Id]"
                     :class="!customList.isOptional ? v$.Tags[customList.Id].$error ? 'p-invalid' : '' : ''"
                     :placeholder="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn"/>
        </CustomLabel>
      </div>
      <div v-else-if="customList && customList.HasMultipleOptions"
           class="w-full">
        <CustomLabel class="w-full"
                     :class="isDesktop ? '' : 'mb-2'"
                     :label="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn"
                     :input="Tags[customList.Id]">
          <MultiSelect class="w-full" :options="customList.TagNames"
                       filter
                       autoFilterFocus
                       v-model="Tags[customList.Id]"
                       :class="!customList.isOptional ? v$.Tags[customList.Id].$error ? 'p-invalid' : '' : ''"
                       :placeholder="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn" />
        </CustomLabel>
      </div>
      <div v-else-if="customList"
           class="w-full">
        <CustomLabel class="w-full"
                     :class="isDesktop ? '' : 'mb-2'"
                     :label="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn"
                     :input="Tags[customList.Id]">
          <Dropdown class="w-full"
                    v-model="Tags[customList.Id]"
                    :options="customList.TagNames"
                    :class="!customList.isOptional ? v$.Tags[customList.Id].$error ? 'p-invalid' : '' : ''"
                    :placeholder="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn" />
        </CustomLabel>
      </div>
    </div>
  </div>

  <div class="flex align-items-center gap-2 cursor-pointer"
       @click="showOptionalParams = !showOptionalParams">
    <i v-if="!showOptionalParams"
       class="pi pi-angle-right"
       style="font-size:2rem"></i>
    <i v-else
       class="pi pi-angle-down"
       style="font-size:2rem"></i>
    <span>{{$t('optionalParams')}}</span>
  </div>

  <div v-if="showOptionalParams">
    <AddContactOptionalParams :contact-data="contactData"
                              @set-optional-params="setOptionalParams" />
  </div>


</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import Uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";
import AddContactOptionalParams from "@/components/contact/add-contact/AddContactOptionalParams";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";

export default {
  name: "AddContact",
  components:{
    AddContactOptionalParams,
    CustomLabel, InputText, Dropdown, MultiSelect
  },
  mixins:[Uuidv4Generator, globalComputedProperties],
  emits:['closePopup'],
  data(){
    return {
      contactData:{
        contactName: '',
        companyName:'',
        companyId: '',
        contactEmail: '',
        optionalParams: {FirstName:'', LastName:''},
        isNewUser: true,
        contactId : this.createUuidv4(),
        Tags:[],
        TagContainer: {},
      },
      company:{},
      companyOptions:[],
      selectedTagContainer:null,
      showOptionalParams:false,
      Tags:{},
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    const validations = {
      contactData: {
        contactName: { required },
        contactEmail: { required },
        optionalParams: {
          LastName: {required},
        }
      },
      company:{required},
      selectedTagContainer:{required},
    };
    // Dynamically define validation rules for each item in customLists
    this.customLists.forEach(customList => {
      validations.Tags = validations.Tags || {};
      if(!customList.isOptional){
        validations.Tags[customList.Id] = { required };
      }
    });

    return validations
  },

  computed:{
    tagContainers(){
      return this.$store.getters.tagContainers.filter(tagContainer => tagContainer.Coverage === "Contacts");
    },

    customLists(){
      const filteredCustomList = [];
      const customLists = this.$store.getters.customLists;
      if(this.selectedTagContainer){
        for(let customList of customLists){
          for(let tag of this.selectedTagContainer.Tags){
            if(tag === customList.Id){
              filteredCustomList.push(customList);
            }
          }
        }
      }
      return filteredCustomList;
    }
  },

  methods: {
    searchForCompanies(event){
      this.$store.commit('setLoading',false);

      clearTimeout(this.searchForCompanies.timeoutId);

      if (event.value.length > 2) {
        this.searchForCompanies.timeoutId = setTimeout(() => {
          this.$store.commit('setLoading',true);
          this.companyOptions = [];
          this.$store.dispatch("getRequest","getCompaniesBySearchValue&query=" + event.value).then(resp => {
            this.$store.commit('setLoading',false);
            if(resp && resp.statusCode === 200){
              this.companyOptions = JSON.parse(resp.body);
            }
          });
        }, 1000);
      }
    },

    setCompany(event){
      const company = {Name:event.Name,Id:event.Id,CompanyNumber:event.CompanyNumber};
      this.company = company;
      const foundCompanyIndex = this.companyOptions.findIndex(filteredCompany => filteredCompany.Id === company.Id);
      if(foundCompanyIndex !== -1){
        this.companyOptions[foundCompanyIndex] = company;
      }else{
        this.companyOptions.push(company);
      }
    },

    setOptionalParams(optionalParams){
      this.contactData.optionalParams = optionalParams;
    },

    addContact() {
      this.v$.$validate();
      if (!this.v$.$error) {

        this.contactData.companyId = this.company.Id;
        this.contactData.companyName = this.company.Name;

        this.contactData.TagContainer = this.selectedTagContainer.id;

        this.customLists.forEach(customList => {
          const foundTag = Object.keys(this.Tags).filter(tag => tag === customList.Id)[0];
          if (customList.isOptional && !foundTag) {
            this.Tags[customList.Id] = "";
          }
        });

        this.customLists.forEach(customList => {
          const foundTag = Object.keys(this.Tags).filter(tag => tag === customList.Id)[0];
          if(foundTag){

            let tagValue = Array.isArray(this.Tags[foundTag]) ? this.Tags[foundTag].flat() : this.Tags[foundTag];

            this.contactData.Tags.push({
              labelName: customList.ClusterName,
              labelNameEn: customList.ClusterNameEn,
              selectedValue: tagValue,
              showAsIndicator: false,
              tagId: foundTag,
            });
          }
        });

        this.$toast.add({severity: 'success', summary: this.$t('confirmed'), detail: this.$t('swal.contactCreated'), life: 3000 });
        this.$store.dispatch('addContact', this.contactData).then(() => {
          this.$emit('close-popup');
        });
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('errorKeysNeeded') + this.getErrors(), life: 5000 });
      }
    },

    getErrors(){
      let errorList = "";
      for(let error of this.v$.$errors){
        if(errorList !== ""){
          errorList = errorList + ", ";
        }
        switch(error.$property){
          case "contactName":
            errorList = errorList + ' ' + this.$t('displayName');
            break;
            case "contactEmail":
              errorList = errorList + ' ' + this.$t('email');
              break;
            case "LastName":
              errorList = errorList + ' ' + this.$t('lastName')
              break;
            case "company":
              errorList = errorList + ' ' + this.$t('company');
              break;
            case "selectedTagContainer":
              errorList = errorList + ' ' + this.$t('classification');
              break;
            default:
              errorList = errorList + ' ' + this.$t('tags');
              break;
        }
      }

      return errorList;
    },
  }
}
</script>

<style scoped>

</style>