<template>
  <div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <DataTable
          ref="dataTable"
          :value="gridDataSource"
          paginator
          :rows="20"
          :rowsPerPageOptions="[10, 20, 50]"
          scrollable
          :scrollHeight="windowHeight"
          dataKey="Id"
          removableSort
          v-model:filters="filter"
          filterDisplay="menu"
          :globalFilterFields="globalFilter">
          <template #header>
            <div class="flex xl:justify-content-end align-items-center">
              <PrimeButton style="height:36px;min-width:36px"
                label="Excel"
                icon="pi pi-file-excel"
                class="mr-2"
                outlined
                severity="secondary"
                @click="exportToExcel" />
              <IconField
                iconPosition="left">
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText v-model="filter['global'].value"
                  :placeholder="$t('placeholder.search')"
                  :style="isDesktop ? '' : 'width:200px;'" />
              </IconField>
            </div>
          </template>
          <Column v-for="(col,index) of columns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :columnKey="col.field"
            :showFilterMatchModes="false"
            :sortable="col.field !== 'Date'"
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="getToolTip(data,field)">
                {{ data[field] }}
              </span>
            </template>
            <template v-if="filter" #filter="{ filterModel}">
              <MultiSelect
                class="p-column-filter"
                v-model="filterModel.value"
                :options="getColumnFilterOptions(col.field)"
                placeholder="any"
                filter
                autoFilterFocus>
                <template #option="slotProps">
                  <div class="flex align-items-center gap-2">
                    <span>{{slotProps.option}}</span>
                  </div>
                </template>
              </MultiSelect>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import QueryBuilderFunctions from "@/mixins/query-builder-functions/queryBuilderFunctions";
import getStoreData from "@/mixins/get-store-data/getStoreData";
import { FilterMatchMode } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import PrimeButton from "primevue/button";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";

export default {
  name: "CorrespondenceQueryTable",
  mixins:[QueryBuilderFunctions, getStoreData],
  data() {
    return {
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'Type', header: this.$t('type') },
        { field: 'Company', header: this.$t('company') },
        { field: 'Creator', header: this.$t('creator') },
        { field: 'ReportType', header: this.$t('reportType') },
        { field: 'Date', header: this.$t('creationDate') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title:{value: null, matchMode: FilterMatchMode.IN},
        Type:{value: null, matchMode: FilterMatchMode.IN},
        Company:{value: null, matchMode: FilterMatchMode.IN},
        Creator:{value: null, matchMode: FilterMatchMode.IN},
        ReportType:{value: null, matchMode: FilterMatchMode.IN},
        Date:{value: null, matchMode: FilterMatchMode.IN},
      },
      globalFilter:['Title', 'Type', 'Company', 'Creator', 'ReportType', 'Date'],
      windowHeight:'',
    };
  },

  components: {
    DataTable, Column, MultiSelect, InputText, PrimeButton, IconField, InputIcon,
  },
  computed:{
    gridDataSource(){
      return this.prepareDataSource();
    },

    getColumnFilterOptions() {
      return (field) => {
        // Extract unique values for the given field
        const uniqueValues = new Set(
          this.gridDataSource.map(item => item[field]).filter(value => value != null) // filter out null/undefined
        );
        return Array.from(uniqueValues).map(value => ( value ));
      };
    },

    closedLeads(){
      return this.$store.getters.closedLeads;
    },

    closedProjects(){
      return this.$store.getters.closedProjects;
    },
  },
  methods: {
    prepareDataSource(){
      const leads = this.$store.getters.leads;
      const projects = this.$store.getters.projects;
      let src = [];
      let data = [...leads, ...projects];
      if(this.closedLeads && this.closedProjects){
        data = [...data, ...this.closedLeads, ...this.closedProjects];
      }

      for(let object of data){
        if(object.Correspondence && object.Correspondence.length > 0){
          for(let correspondence of object.Correspondence){
            if(correspondence.Type && correspondence.Type !== 'Beitrag'){
              let obj = {};

              obj["Title"] = object["Title"];
              obj["Type"] = this.$t(object["Type"].toLowerCase());
              obj["Company"] = this.getValFromObject(object.Company, "Name");
              obj["Creator"] = correspondence["Author"];
              obj["ReportType"] = correspondence["Type"];
              obj["Date"] =  new Date(Number(correspondence.Unix)).toLocaleDateString();

              src.push(obj);
            }
          }
        }
      }

      return src;
    },

    getToolTip(data,field){
      return data[field];
    },

    exportToExcel(){
      this.$refs.dataTable.exportCSV();
    },
  },
  mounted(){
    if(!this.closedLeads)this.getClosedLeads();
    if(!this.closedProjects)this.getClosedProjects();
    this.windowHeight = window.innerHeight * 0.56 + 'px';
  }
}
</script>

<style scoped>
.headerLabel{
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.p-multiselect-label){
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.p-datatable .p-datatable-tbody > tr > td){
  padding: 8px 21px !important;
}
</style>