<template>
  <div v-if="!loading" style="min-height: 500px">
    <TabView v-model:activeIndex="activeTab">
      <TabPanel :header="this.$t('projects') + ' (' + this.projectCount + ')'">
        <ProjectQueryTable/>
      </TabPanel>
      <TabPanel :header="this.$t('leads') + ' (' + this.leadCount + ')'">
        <LeadQueryTable />
      </TabPanel>
      <TabPanel :header="this.$t('companies') + ' (' + this.companyCount + ')'">
        <CompanyQueryTable />
      </TabPanel>
      <TabPanel :header="this.$t('persons') + ' (' + this.contactCount + ')'">
        <PersonQueryTable />
      </TabPanel>
      <TabPanel :header="this.$t('offers') + ' (' + this.offerCount + ')'">
        <OfferQueryTable />
      </TabPanel>
      <TabPanel :header="this.$t('sales') + ' (' + this.saleCount + ')'">
        <SalesQueryTable />
      </TabPanel>
      <TabPanel :header="this.$t('reports') + ' (' + this.correspondenceCount + ')'">
        <CorrespondenceQueryTable />
      </TabPanel>
    </TabView>
  </div>
</template>

<script>

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import ProjectQueryTable from "@/components/projects/project-query-table/ProjectQueryTable";
import LeadQueryTable from "@/components/leads/lead-query-table/LeadQueryTable";
import PersonQueryTable from "@/components/persons/person-query-table/PersonQueryTable";
import CompanyQueryTable from "@/components/company/company-query-table/CompanyQueryTable";
import SalesQueryTable from "@/components/sales/sales-query-table/SalesQueryTable";
import OfferQueryTable from "@/components/offers/offer-query-table/OfferQueryTable";
import CorrespondenceQueryTable from "@/components/correspondence/correspondence-query-table/CorrespondenceQueryTable";

export default {
  name: "QueryBuilder",
  components: {
    CorrespondenceQueryTable,
    SalesQueryTable, OfferQueryTable,
    LeadQueryTable, ProjectQueryTable, PersonQueryTable, CompanyQueryTable, TabPanel, TabView},
  data(){
    return {
      activeTab: 0,
    }
  },
  computed:{
    loading(){
      return this.$store.getters.loading;
    },

    projectCount(){
      return this.$store.getters.projects.length;
    },

    leadCount(){
      return this.$store.getters.leads.length;
    },
    
    companyCount(){
      return this.$store.getters.companyQueryData.length;
    },
    
    contactCount(){
      return this.$store.getters.contactQueryData.length;
    },
    
    offerCount(){
      return this.$store.getters.offers.length;
    },
    
    saleCount(){
      return this.$store.getters.sales.length;
    },

    correspondenceCount(){
      const leads = this.$store.getters.leads;
      const closedLeads = this.$store.getters.closedLeads;
      const projects = this.$store.getters.projects;
      const closedProjects = this.$store.getters.closedProjects;
      let count = 0;
      let data = [...leads, ...projects];
      if(closedLeads && closedProjects){
        data = [...data, ...closedLeads, ...closedProjects];
      }

      for(let object of data){
        if(object.Correspondence && object.Correspondence.length > 0){
          for(let correspondence of object.Correspondence){
            if(correspondence.Type && correspondence.Type !== 'Beitrag'){
              count++;
            }
          }
        }
      }
      return count;
    }
    
  },
}
</script>

<style scoped>

</style>