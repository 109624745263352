<template>
  <MaterialTable 
    :data-key="'timestamp'"
    :table-id="'companyCorrespondences'"
    :style="{'height': windowHeight}"
    :data-prop="correspondences"
    :columns="columns"
    :has-paginator="true"
    :rows-count="10"
    :has-global-filter="true"
    :filter-prop="filter"
    :filter-options="filterOptions"
    :sortable="true"
    :has-removable-sort="true"
    @row-selected="emitCorrespondenceClicked"
    :show-delete-button="true"
    @row-deleted="deleteCorrespondence"
    :key="tableKey"
    :selection-mode="'single'"
    :show-add-button="true"
    @open-dialog="creatingCorrespondence = true"
    :resizable-columns="true"/>

  <Popup
    :maximizable="true"
    :header="this.$t('createCorrespondence')"
    :modal="true"
    :visible="creatingCorrespondence"
    :show-save-button="true"
    @button-clicked="submitAddCorrespondencePopup"
    @close-popup="creatingCorrespondence = false"
    styling="width:50%;max-height:70%" >
    <AddCorrespondence 
      ref="addCorrespondence"
      :correspondence-type="$t('company')"
      :company-prop="company"
      @close-popup="creatingCorrespondence = false" />
  </Popup>

</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import Popup from "@/components/global-components/popup/Popup";
import {FilterMatchMode} from "primevue/api";
import AddCorrespondence from "../../../correspondence/add-correspondence/AddCorrespondence.vue";

export default {
  name: "CompanyMaterialTableCorrespondenceTab",
  components:{MaterialTable, Popup, AddCorrespondence},
  emits:['correspondenceClicked'],
  data(){
    return{
      columns:[
        { field: 'text', header: this.$t('description') },
        { field: 'sender', header: this.$t('responsible') },
        { field: 'stage', header: this.$t('assignment') },
        { field: 'file', header: this.$t('files') },
        { field: 'timestamp', header: this.$t('creationDate') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        text: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sender: { value: null, matchMode: FilterMatchMode.CONTAINS },
        stage: { value: null, matchMode: FilterMatchMode.CONTAINS },
        file: { value: null, matchMode: FilterMatchMode.CONTAINS },
        timestamp: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['text', 'sender', 'stage'],
      tableKey:0,
      selectedCorrespondence:null,
      windowHeight: '',
      creatingCorrespondence: false,
    }
  },
  props:{
    correspondences:Array,
    company:Object,
  },

  methods:{
    emitCorrespondenceClicked(correspondence){
      const data = {data:correspondence, type:'Correspondence'};
      this.$emit('correspondence-clicked',data);
    },

    deleteCorrespondence(correspondence){
      this.$confirm.require({
        header: this.$t('swal.deleteCorrespondenceHeader'),
        message: this.$t('swal.deleteCorrespondenceText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          const data = {"Company":this.company};
          data.Company.Correspondence = data.Company.Correspondence
              .filter(foundCorrespondence => foundCorrespondence.timestamp !== correspondence.timestamp);

          // maps through the correspondence array and sets the timestamp to a number instead of a string.
          data.Company.Correspondence = data.Company.Correspondence.map(obj => ({
            ...obj,
            timestamp: Number(obj.timestamp)
          }));

          this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.correspondenceDeleted'), life: 3000 });
          this.$store.dispatch('deleteCorrespondence',data).then(() => {
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    submitAddCorrespondencePopup(){
      this.$refs.addCorrespondence.checkIfFilesNotUploaded();
    },
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>