<template>
  <div class="flex justify-content-between gap-2 pt-2">
      <Dropdown v-model="projectPhase" :options="phaseOptions" optionLabel="Name" class="w-full"
                :class="v$.projectPhase.$error ? 'p-invalid' : ''" :placeholder="this.$t('placeholder.phase')"/>
      <Dropdown v-model="visibleTo" :options="visibleToOptions" class="w-full"
                :class="v$.visibleTo.$error ? 'p-invalid' : ''" :placeholder="this.$t('placeholder.visibleTo')"/>
  </div>

  <div v-if="visibleTo === 'Members'" class="pt-2">
    <MultiSelect v-model="correspondenceMembers" :options="members" optionLabel="Name" class="w-full"
                 filter :placeholder="this.$t('placeholder.selectMembers')" autoFilterFocus/>
  </div>

  <div class="mb-2 mt-2">
    <CustomLabel class="w-full"
                :label="this.$t('assignment')"
                :input="stage">
      <Dropdown class="w-full"
                v-model="stage"
                :options="stageOptions"
                filter
                :placeholder="this.$t('placeholder.assignment')"
                autoFilterFocus />
    </CustomLabel>
  </div>

  <div class="mt-2">
    <ContactSection :company-id="companyId"
                    :contact-props="contact"
                    :contact-options="contactOptions"
                    :show-load-button="true"
                    :is-dropdown="true"
                    @contact-changed="setContact"
                    @get-contacts="getContactsByCompanyId" />
  </div>

  <div class="mb-2 mt-2">
    <CustomLabel>
      <Calendar class="w-full"
                :modelValue="new Date(unix)"
                dateFormat="dd.mm.yy"
                showIcon
                hideOnDateTimeSelect
                @update:modelValue="setCorrespondenceTimestampUnix($event)"/>
    </CustomLabel>
  </div>

  <div class="pt-2">
    <PrimeTextarea class="w-full" v-model="description" id="Description" maxlength="500"
                   :class="v$.description.$error ? 'p-invalid' : ''" :placeholder="this.$t('placeholder.description')" />
  </div>

  <div class="pt-2">
    <FileUpload ref="fileUpload" name="demo[]" :customUpload="true" @uploader="uploadFiles" :multiple="true"
                accept=".jpg,.jpeg,.png,.eml,.msg,.doc,.docx,.pdf,.ppt,.pptx,.txt,.xls,.xlsx" :maxFileSize="52000000">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
  </div>

  <div v-if="files.length > 0">
    <AttachmentMaterialTable :attachments="files" :show-delete-button="true" @delete-attachment="deleteFile" />
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import PrimeTextarea from "primevue/textarea";
import FileUpload from "primevue/fileupload";
import Calendar from "primevue/calendar";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import Uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";
import AttachmentMaterialTable from "@/components/global-components/attachment-material-table/AttachmentMaterialTable";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import ContactSection from "@/components/global-components/contact-section/ContactSection";
import MSGReader from '@kenjiuno/msgreader';
import sanitizeFileName from "../../../mixins/sanitize-file-name/sanitize-file-name";

export default {
  name: "CreateCorrespondence",
  components:{ContactSection, AttachmentMaterialTable, Dropdown, MultiSelect, PrimeTextarea, FileUpload, Calendar},
  mixins:[Uuidv4Generator,S3FileManager,globalComputedProperties, sanitizeFileName],
  emits: ['addCorrespondence'],
  data(){
    return{
      contact: {Id:"",Name:"",Email:"",Phone:"",Landline:""},
      contactOptions:[],
      correspondenceMembers: null,
      description:null,
      files:[],
      id:this.createUuidv4(),
      projectPhase:null,
      stage: "",
      visibleTo:'All',
      emailContent:null,
      unix: new Date().getTime(),
    }
  },

  props:{
    companyId:String,
    members:Array,
    projectId:String,
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      projectPhase: {required},
      visibleTo: {required},
      description: {required},
    }
  },

  computed:{
    phaseOptions(){
      return this.$store.getters.projectPhases;
    },

    visibleToOptions(){
      return ['Self','Members','All']
    },

    currentUserData(){
      let currentUser = {};
      const responsibles = this.$store.getters.responsibles;
      for(let responsible of responsibles){
        if(responsible.User === this.$store.getters.username){
          currentUser = responsible;
          break;
        }
      }

      return currentUser
    },

    stageOptions(){
      const foundTagContainer = this.$store.getters.allTagContainers.find(tagContainer => tagContainer.Coverage === "ticket_stages");
      let stageOptions = [];
      if(foundTagContainer){
        for(let tag of foundTagContainer.Tags){
          const foundCustomList = this.$store.getters.allCustomLists.find(customList => customList.Id === tag);
          if(foundCustomList){
            stageOptions = [...stageOptions, ...foundCustomList.TagNames];
          }
        }
      }

      return stageOptions
    }
  },

  methods:{
    setCorrespondenceTimestampUnix(event){
      this.unix = event.getTime();
    },

    setContact(event){
      this.contact = event;
    },

    pushCreatorIntoMembers(){
      const creator = this.$store.getters.responsibles.filter(responsible => responsible.User === this.$store.getters.username)[0];
      this.correspondenceMembers.push({Id:creator.User,Name:creator.FriendlyName,type:"User"});
    },

    uploadFiles(event){
      for (let file of event.files){
        let fileName = this.getFileName(file.name);
        const fileType = this.getFileType(file.name);
        this.$store.commit('setLoading',true);
        let filePath = 'sales/leadsAndProjects/' + this.projectId + '/correspondences/' + this.id + '/';
        // Check if it's an email file
        if (file.type.includes('message')) {
          // Wait for email content extraction asynchronously
          this.getEmailText(file).then(() => {
            fileName = ' ' + `${this.$t('date')}: ${this.emailContent.date}` + ' ' + fileName;
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
            this.s3UploadFile(file, filePath).then(() => {
              this.files.push({
                Name:fileName,
                Type:fileType,
                LastModified:new Date(file.lastModified).toLocaleString(),
                Size:(file.size / (1024*1024)).toFixed(2) + " MB",
                "Key": filePath,
              });
              this.$store.commit('setLoading',false);
            }).catch((err) => {
              console.log(err);
              this.$store.commit('setLoading',false);
            });
          });
        }else if (file.name.endsWith('.msg')) {
          this.readMsgFile(file).then((emailData) => {
            fileName = ' ' + `${this.$t('date')}: ${emailData.date}` + ' ' + fileName;
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
            this.s3UploadFile(file, filePath).then(() => {
              this.files.push({
                Name:fileName,
                Type:fileType,
                LastModified:new Date(file.lastModified).toLocaleString(),
                Size:(file.size / (1024*1024)).toFixed(2) + " MB",
                "Key": filePath,
              });
              this.$store.commit('setLoading',false);
            }).catch((err) => {
              console.log(err);
              this.$store.commit('setLoading',false);
            });
          });
          }else{
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
            this.s3UploadFile(file, filePath).then(() => {
              this.files.push({
                Name:fileName,
                Type:fileType,
                LastModified:new Date(file.lastModified).toLocaleString(),
                Size:(file.size / (1024*1024)).toFixed(2) + " MB",
                "Key": filePath,
              });
            this.$store.commit('setLoading',false);
          }).catch((err) => {
            console.log(err);
            this.$store.commit('setLoading',false);
          });
        }
      }
    },

    getEmailText(file){
      return new Promise((resolve) => {
        const reader = new FileReader();
        
        // Read the file as text or binary, depending on your email file format (.eml or .msg)
        reader.onload = (e) => {
          const emailContent = e.target.result;
          this.emailContent = this.parseEmailContent(emailContent);
          resolve();
        };
        
        // Read as text (suitable for .eml files)
        reader.readAsText(file);
      });
    },

    async readMsgFile(file) {
      return new Promise((resolve) => {
        const fileReader = new FileReader();

        // When the file is loaded, use msg-parser to parse it
        fileReader.onload = async (e) => {
          const arrayBuffer = e.target.result;

          try {
            const msgReader = new MSGReader(arrayBuffer);
            const fileData = msgReader.getFileData();

            const emailData = {
            date: fileData.creationTime.replace(/,/g, "") || 'Unknown',
          };

          resolve(emailData)

          } catch (err) {
            console.error('Error parsing .msg file:', err);
          }
        };

        // Read the file as an ArrayBuffer
        fileReader.readAsArrayBuffer(file);
      });
    },

    getEmailFromSubject(emailString){
      return emailString.match(/<([^>]+)>/)[1];
    },

    parseEmailContent(emailContent) {
      // Parsing .eml content (simple approach for text-based emails)
      const emailLines = emailContent.split("\n");

      let date = "";

      emailLines.forEach(line => {
        if (line.startsWith("Date:")) {
          date = line.replace("Date:", "").replace(/,/g, "").replace(/\s\+.*/, "").trim();
        }
      });

      return {
        date,
      }
    },

    deleteFile(file){
      this.$confirm.require({
        header: this.$t('swal.deleteFileHeader'),
        message: this.$t('swal.deleteFileText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('deleteS3Object',file.Key).then(() => {
            this.files = this.files.filter(foundFile => foundFile.Key !== file.Key);
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.fileDeleted'), life: 3000 });
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    checkIfFilesNotUploaded(){
      if(this.$refs.fileUpload.files.length > 0) {
        this.$confirm.require({
          header: this.$t('swal.submitWithoutUploadingHeader'),
          message: this.$t('swal.submitWithoutUploadingText'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.submitEdit();
          },
          reject: () => {
            this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
          }
        });
      }else{
        this.submitEdit();
      }
    },

    submitEdit(){
      this.v$.$validate();
      if(!this.v$.$error) {
        if(this.visibleTo === "Members"){
          if(!this.correspondenceMembers){
            this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.noMembersSelected'), life: 3000 });
            return;
          }else{
            const creatorInMembers = this.correspondenceMembers.filter(member => member.Id === this.$store.getters.username)[0];

            if(!creatorInMembers) this.pushCreatorIntoMembers();
          }
        }else if(this.visibleTo === "Self") {
          this.correspondenceMembers = [
              {
                Id: this.currentUserData.User,
                Name: this.currentUserData.FriendlyName,
                type:"User"
              }
          ];
        }

        const correspondence = {
          "Phase": this.projectPhase,
          "Text": this.description,
          "Id": this.id,
          "Author": this.username,
          "Members":this.correspondenceMembers,
          "VisibleTo": this.visibleTo ,
          "Unix": this.unix,
          "Files": this.createFilesArray(),
          "Contact":this.contact,
          "stage": this.stage,
          "Type": "Beitrag"
        }
        this.$emit('add-correspondence',correspondence);
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('errorKeysNeeded') + this.getErrors(), life: 5000 });
      }
    },

    createFilesArray(){
      const files = [];
      for(let file of this.files){
        files.push({path:file.Key, name:file.Name});
      }
      return files;
    },

    getErrors(){
      let errorList = "";
      for(let error of this.v$.$errors){
        if(errorList !== ""){
          errorList = errorList + ", ";
        }
        switch(error.$property){
          case "projectPhase":
            errorList = errorList + ' ' + this.$t('placeholder.phase');
            break;
          case "visibleTo":
            errorList = errorList + ' ' + this.$t('placeholder.visibleTo');
            break;
          case "description":
            errorList = errorList + ' ' + this.$t('description')
            break;
        }
      }

      return errorList;
    },

    getContactsByCompanyId(){
      this.$store.commit('setLoading',true);
      this.$store.dispatch("getRequest","getContactsByCompanyId&query=" + this.companyId).then(resp => {
        this.$store.commit('setLoading',false);
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.contactOptions = body.map(contact => ({ Name: contact.ContactName,
            Id:contact.ContactId,
            Email:contact.ContactEmail,
            Phone:contact.OptionalParams ? contact.OptionalParams.Mobile ? contact.OptionalParams.Mobile : "" : "",
            Landline:contact.OptionalParams ? contact.OptionalParams.Landline ? contact.OptionalParams.Landline : "" : ""}));
        }
      });
    },
  },
}
</script>

<style scoped>

</style>