
const state = {
    tasks:[],
};

const getters = {
    tasks:(state) => state.tasks,
};

const actions = {
    addTask(context,task){
        context.commit('setLoading',true);
        return new Promise((resolve) => {
            context.dispatch("postRequest",{
                "param":"createTask",
                "payload":task,
            }).then(()=> {
                context.commit('addTask',task);
                context.commit('setLoading',false);
                resolve();
            });
        });
    },

    editTask(context,task){
        context.commit('setLoading',true);
        return new Promise((resolve) => {
            context.dispatch("postRequest",{
                "param":"editTask",
                "payload":task,
            }).then(()=> {
                context.commit('editTask',task);
                context.commit('setLoading',false);
                resolve();
            });
        });
    },

    deleteTask(context, task) {
        context.commit('setLoading',true);
        return new Promise((resolve) => {
            context.dispatch("postRequest",{
                "param":"deleteTask",
                "payload":task.Id,
            }).then(()=> {
                context.commit('deleteTask',task);
                context.commit('setLoading',false);
                resolve();
            });
        });
    },

    closeTask(context,task){
        context.commit('setLoading',true);
        return new Promise((resolve) => {
            context.dispatch("postRequest",{
                "param":"closeTask",
                "payload":task,
            }).then(()=> {
                context.commit('editTask',task);
                context.commit('setLoading',false);
                resolve();
            });
        });
    },

    reopenTask(context,task){
        context.commit('setLoading',true);
        return new Promise((resolve) => {
            context.dispatch("postRequest",{
                "param":"reopenTask",
                "payload":task,
            }).then(()=> {
                context.commit('editTask',task);
                context.commit('setLoading',false);
                resolve();
            });
        });
    }
};

const mutations = {
    // We need to add all tasks this way, bc when we start inside the projectDashboard,
    // we first add the closedTasks and afterwards the getRequest for allTasks is fired.
    // IF we were to set the Tasks via the old method (state.tasks = tasks), we would overwrite
    // the closed tasks we added from projectDashboard.
    // Else we update the found task. (In case we click the update button in a table)
    addTasks(state,tasks){
        for(let task of tasks){
            const foundTask = state.tasks.filter(currentTask => currentTask.Id === task.Id)[0];
            const foundTaskIndex = state.tasks.findIndex(currentTask => currentTask.Id === task.Id);
            if(!foundTask){
                state.tasks.push(task);
            }else{
                state.tasks[foundTaskIndex] = task;
            }
        }
        state.tasks = state.tasks.sort((a, b) => {
            if (a['Title'] < b['Title']) return -1;
            if (a['Title'] > b['Title']) return 1;
        });
    },

    addTask(state,task){
        state.tasks.push(task);
    },

    editTask(state,task){
        const foundTask = state.tasks.findIndex(currentTask => currentTask.Id === task.Id);
        state.tasks[foundTask] = task;
    },

    deleteTask(state,task){
        state.tasks = state.tasks.filter(foundTask => foundTask.Id !== task.Id);
    }
};

const tasksStore = {
    state,
    getters,
    actions,
    mutations,
}

export default tasksStore;