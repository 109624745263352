<template>
  <div class="mb-2 mt-2">
    <span v-if="showFirstReminder">{{this.$t('firstReminder')}}: {{showFirstReminder}}</span>
  </div>
  <div class="flex align-items-center gap-1">
    <InputSwitch v-model="hasKnownResponsible"
                 :disabled="disabled" />
    <span v-if="hasKnownResponsible">{{this.$t('existingCustomer')}}</span>
    <span v-else>{{this.$t('interested')}}</span>
  </div>
  <div class="mb-2 mt-2"
       :class="isDesktop ? 'flex justify-content-between gap-2' : ''">
    <CustomLabel class="w-full"
                 :class="isDesktop ? '' : 'mb-2'"
                 :label="this.$t('title')"
                 :input="leadData.Title">
      <InputText class="w-full"
                 :disabled="disabled"
                 v-model="leadData.Title"
                 :class="v$.leadData.Title.$error ? 'p-invalid' : ''"
                 :placeholder="this.$t('placeholder.title')"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('responsible')"
                 :input="leadData.Responsible ? leadData.Responsible.Name : null">
      <Dropdown class="w-full"
                :disabled="disabled"
                v-model="leadData.Responsible"
                :class="v$.leadData.Responsible.$error ? 'p-invalid' : ''"
                :options="responsibleOptions"
                optionLabel="Name"
                filter
                :placeholder="this.$t('placeholder.responsible')"
                autoFilterFocus/>
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2"
       :class="isDesktop ? 'flex justify-content-between gap-2' : ''">
    <CustomLabel v-if="hasKnownResponsible"
                 class="w-full"
                 :class="isDesktop ? '' : 'mb-2'"
                 :label="this.$t('company')"
                 :input="leadData.Company ? leadData.Company.Name : null">
      <Dropdown class="w-full"
                :disabled="disabled"
                v-model="leadData.Company"
                :options="companyOptions"
                :optionLabel="getCompanyOptionLabel"
                autoFilterFocus
                :class="v$.leadData.Company.$error ? 'p-invalid' : ''"
                filter
                :placeholder="this.$t('placeholder.company')"
                @update:modelValue="setLeadCompany"
                @filter="searchForCompanies"/>
    </CustomLabel>
    <CustomLabel v-else
                 class="w-full"
                 :class="isDesktop ? '' : 'mb-2'"
                 :label="this.$t('interested')"
                 :input="leadData.Company ? leadData.Company.Name : null">
      <InputText class="w-full"
                 :disabled="disabled"
                 :modelValue="leadData.Company ? leadData.Company.Name : ''"
                 :class="v$.leadData.Company.$error ? 'p-invalid' : ''"
                 maxlength="100"
                 :placeholder="this.$t('placeholder.interested')"
                 @update:modelValue="setCompany" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 label="Prio"
                 :input="leadData.Prio">
      <Dropdown class="w-full"
                :disabled="disabled"
                v-model="leadData.Prio"
                :options="['A','B','C']"
                :placeholder="this.$t('placeholder.prio')" />
    </CustomLabel>
  </div>

  <div v-if="leadData.Company"
       class="mb-2 mt-2">
    <div v-if="hasKnownResponsible"
         class="mb-2 mt-2"
         :class="isDesktop ? 'flex justify-content-between gap-2' : ''">
      <CustomLabel class="w-full"
                   :class="isDesktop ? '' : 'mb-2'"
                   :label="$t('location')"
                   :input="leadData.Location ? leadData.Location.LocationName : ''">
        <Dropdown class="w-full"
                  v-model="leadData.Location"
                  :options="locationOptions"
                  optionLabel="LocationName"
                  :disabled="disabled"
                  :placeholder="$t('placeholder.location')" />
      </CustomLabel>

      <CustomLabel class="w-full"
                   :label="$t('supplier')"
                   :input="leadSupplier">
        <Dropdown class="w-full"
                  v-model="leadSupplier"
                  :options="supplierOptions"
                  :disabled="disabled"
                  optionLabel="Name"
                  @update:modelValue="setLeadSupplier"
                  :placeholder="$t('placeholder.supplier')" />
      </CustomLabel>
    </div>

    <ContactSection :company-id="leadData.Company.Id"
                    :disabled="disabled"
                    :contact-props="leadData.Contact"
                    :contact-options="contactOptions"
                    :is-dropdown="hasKnownResponsible"
                    :show-load-button="true"
                    @contact-changed="setContact"
                    @get-contacts="getContactsOptions"/>
  </div>

  <div class="mb-2 mt-2"
       :class="isDesktop ? 'flex justify-content-between gap-2' : ''">
    <CustomLabel class="w-full"
                 :class="isDesktop ? '' : 'mb-2'"
                 :label="this.$t('members')"
                 :input="leadData.Members[0] ? leadData.Members[0].Name : ''">
      <MultiSelect class="w-full"
                   :disabled="disabled"
                   v-model="leadData.Members"
                   :options="membersOptions"
                   optionLabel="Name"
                   filter
                   :placeholder="this.$t('placeholder.selectMembers')"
                   autoFilterFocus/>
    </CustomLabel>

    <CustomLabel class="w-full" :label="this.$t('budget')" :input="leadData.Budget">
      <InputNumber class="w-full"
                   :disabled="disabled"
                   v-model="leadData.Budget"
                   :placeholder="this.$t('placeholder.budget')" />
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2">
    <CustomLabel :label="this.$t('description')" :input="leadData.Description">
      <Editor class="w-full"
              editorStyle="height: 320px"
              v-model="leadData.Description"
              id="Description"
              :readonly="disabled"
              :placeholder="this.$t('placeholder.description')"> 
        <template v-slot:toolbar>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
          </span>
          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-list" value="check"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-clean"></button>
          </span>
        </template>
      </Editor>
    </CustomLabel>
  </div>

  <div v-if="hasKnownResponsible" class="flex align-items-center gap-1">
    <InputSwitch v-model="hasEquipment" :disabled="disabled" />
    <span v-if="hasEquipment">{{this.$t('hasEquipments')}}</span>
    <span v-else>{{this.$t('hasNoEquipments')}}</span>
  </div>

  <div v-if="hasEquipment"
       class="mb-2 mt-2"
       :class="isDesktop ? 'flex justify-content-between gap-2' : ''">
    <CustomLabel class="w-full"
                 :label="this.$t('equipments')"
                 :input="leadData.Equipment.length > 0 ?leadData.Equipment[0].Name : ''">
      <MultiSelect class="w-full"
                   :disabled="disabled"
                   v-model="leadData.Equipment"
                   :options="equipmentOptions"
                   optionLabel="Name"
                   filter
                   :placeholder="this.$t('placeholder.equipments')"
                   autoFilterFocus/>
    </CustomLabel>
  </div>

  <div class="mt-4 mb-4 border-bottom-1"/>

  <div class="mb-6 mt-2">
    <div class="mb-2">Tags</div>
    <div>
      <div class="w-full mb-2">
        <CustomLabel class="w-full"
                     :label="this.$t('classification')"
                     :input="selectedTagContainer">
          <Dropdown class="w-full"
                    v-model="selectedTagContainer"
                    :options="tagContainers"
                    optionLabel="ContainerName"
                    :class="v$.selectedTagContainer.$error ? 'p-invalid' : ''"
                    :placeholder="this.$t('classification')"
                    :disabled="leadData.LeadTagContainer ? !!leadData.LeadTagContainer.id : false"/>
        </CustomLabel>
      </div>

      <div v-for="(customList) of customLists"
           class="mb-2 mt-2"
           :class="isDesktop ? 'flex justify-content-between gap-2' : ''"
           :key="customList.Id">
        <div v-if="customList && customList.FieldParams.IsInputField"
             class="w-full">
          <CustomLabel class="w-full"
                       :class="isDesktop ? '' : 'mb-2'"
                       :label="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn"
                       :input="leadData.LeadTags[customList.Id]">
            <InputText class="w-full"
                       v-model="leadData.LeadTags[customList.Id]"
                       :disabled="disabled"
                       :class="!customList.isOptional ? v$.leadData.LeadTags[customList.Id].$error ? 'p-invalid' : '' : ''"
                       :placeholder="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn"/>
          </CustomLabel>
        </div>
        <div v-else-if="customList && customList.HasMultipleOptions"
             class="w-full">
          <CustomLabel class="w-full"
                       :class="isDesktop ? '' : 'mb-2'"
                       :label="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn"
                       :input="leadData.LeadTags[customList.Id]">
            <MultiSelect class="w-full"
                         v-model="leadData.LeadTags[customList.Id]"
                         :options="customList.TagNames"
                         filter
                         autoFilterFocus
                         :class="!customList.isOptional ? v$.leadData.LeadTags[customList.Id].$error ? 'p-invalid' : '' : ''"
                         :placeholder="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn"
                         :disabled="disabled"  />
          </CustomLabel>
        </div>
        <div v-else-if="customList"
             class="w-full">
          <CustomLabel class="w-full"
                       :class="isDesktop ? '' : 'mb-2'"
                       :label="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn"
                       :input="leadData.LeadTags[customList.Id]">
            <Dropdown class="w-full"
                      v-model="leadData.LeadTags[customList.Id]"
                      :options="customList.TagNames"
                      :class="!customList.isOptional ? v$.leadData.LeadTags[customList.Id].$error ? 'p-invalid' : '' : ''"
                      :placeholder="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn"
                      :disabled="disabled"  />
          </CustomLabel>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!disabled"
       class="mb-2 mt-2">
    <FileUpload ref="fileUpload"
                name="demo[]"
                :customUpload="true"
                @uploader="uploadFiles"
                :multiple="true"
                accept=".jpg,.jpeg,.png,.eml,.msg,.doc,.docx,.pdf,.ppt,.pptx,.txt,.xls,.xlsx"
                :maxFileSize="52000000">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
  </div>

  <div v-if="files.length > 0">
    <AttachmentMaterialTable :attachments="files"
                             :show-delete-button="!disabled"
                             @delete-attachment="deleteFile" />
  </div>

  <Popup position="top"
         :visible="showReminder"
         :header="this.$t('leadReminder')"
         :styling="isDesktop ? 'width:60%' : 'width:100%'"
         @close-popup="emitCloseReminders">
    <LeadReminder :lead-data-prop="leadData"
                  :reminders-prop="leadData.Reminders"
                  @close-popup="this.closePopup"/>
  </Popup>
</template>

<script>

import Popup from "@/components/global-components/popup/Popup";
import LeadReminder from "@/components/leads/lead-reminder/LeadReminder";
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import AttachmentMaterialTable from "@/components/global-components/attachment-material-table/AttachmentMaterialTable";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Editor from "primevue/editor";
import FileUpload from 'primevue/fileupload';
import InputSwitch from 'primevue/inputswitch';
import InputNumber from "primevue/inputnumber";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";
import ContactSection from "@/components/global-components/contact-section/ContactSection";
import uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import MSGReader from '@kenjiuno/msgreader';
import sanitizeFileName from "../../../mixins/sanitize-file-name/sanitize-file-name";

export default {
  name: "EditLead",
  components:{
    Popup, LeadReminder, CustomLabel, AttachmentMaterialTable, ContactSection,
    InputText, Dropdown, MultiSelect, Editor, FileUpload, InputSwitch, InputNumber},
  mixins:[S3FileManager, uuidv4Generator, globalComputedProperties, sanitizeFileName],
  emits: ['closeReminders', 'submitEditLead'],
  props:{
    leadDataProp:Object,
    disabled:Boolean,
    showReminder:Boolean,
  },

  data(){
    return{
      leadData: JSON.parse(JSON.stringify(this.leadDataProp)),
      files:[],
      hasEquipment: false,
      hasKnownResponsible:true,
      selectedTagContainer:null,
      companyOptions:[],
      locationOptions:[],
      contactOptions:[],
      supplierOptions:[],
      emailContent:null,
      leadSupplier: {},
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  /**
   * Validates my leadData keys + the selectedTagContainer variable.
   * We go through each entry of the customLists array and check,
   * if the customList is required. If it is, we add the customListId
   * into the LeadTags and set is as required.
   */
  validations(){
    const validations = {
      leadData: {
        Title: { required },
        Responsible: { required },
        Company: { required },
      },
      selectedTagContainer:{required},
    };
    // Dynamically define validation rules for each item in customLists
    this.customLists.forEach(customList => {
      validations.leadData.LeadTags = validations.leadData.LeadTags || {};
      if(!customList.isOptional){
        validations.leadData.LeadTags[customList.Id] = { required };
      }
    });

    return validations;
  },

  computed:{
    responsibleOptions(){
      return this.$store.getters.responsibles.map(responsible => ({Name:responsible.FriendlyName,Id:responsible.User, type: "User"}));
    },

    membersOptions(){
      let members = [];
      members = [
        ...this.contactOptions
            .map(contact => ({ Name: contact.Name, Id: contact.Id, type: "Contact" })),

        ...this.$store.getters.responsibles
            .map(user => ({ Name: user.FriendlyName, Id: user.User, type: "User" })),
      ];

      return members.sort((a,b) => {
        if (a['Name'] < b['Name']) return -1;
        if (a['Name'] > b['Name']) return 1;
      });
    },

    equipmentOptions(){
      const equipments = this.$store.getters.equipments;
      let changedEquipmentData = equipments.map(equipment => ({Name: equipment.EquipmentName, Id: equipment.Id}));

      if (this.leadData.Company) {
        changedEquipmentData = equipments.filter(equipment => equipment.CompanyId === this.leadData.Company.Id)
            .map(equipment => ({Name: equipment.EquipmentName, Id: equipment.Id}));
      }

      return changedEquipmentData;
    },

    showFirstReminder(){
      const reminders = this.leadData.Reminders;

      if(reminders.length > 0){
        reminders.sort((a, b)=>{
          if (a['Created'] < b['Created']) return -1;
          if (a['Created'] > b['Created']) return 1;
        });

        return new Date(Number(reminders[0].Unix)).toLocaleDateString() + ' (' + reminders[0].Text + ')';
      }

      return null;
    },

    tagContainers(){
      return this.$store.getters.tagContainers.filter(tagContainer => tagContainer.Coverage === "Leads");
    },

    customLists(){
      const filteredCustomList = [];
      const customLists = this.$store.getters.customLists;
      if(this.selectedTagContainer){
        for(let customList of customLists){
          for(let tag of this.selectedTagContainer.Tags){
            if(tag === customList.Id){
              filteredCustomList.push(customList);
            }
          }
        }
      }

      return filteredCustomList;
    }
  },

  methods:{
    getContactsOptions(){
      this.getContactsByCompanyId(this.leadData.Company.Id);
    },

    searchForCompanies(event){
      this.$store.commit('setLoading',false);

      clearTimeout(this.searchForCompanies.timeoutId);

      if (event.value.length > 2) {
        this.searchForCompanies.timeoutId = setTimeout(() => {
          this.$store.commit('setLoading',true);
          this.companyOptions = [];
          this.$store.dispatch("getRequest","getCompaniesBySearchValue&query=" + event.value).then(resp => {
            this.$store.commit('setLoading',false);
            if(resp && resp.statusCode === 200){
              this.companyOptions = JSON.parse(resp.body);
            }
          });
        }, 1000);
      }
    },

    setLeadCompany(event){
      const company = {Name:event.Name, Id:event.Id, CompanyNumber:event.CompanyNumber, City:event.City};
      this.leadData.Company = company;
      const foundCompanyIndex = this.companyOptions.findIndex(filteredCompany => filteredCompany.Id === company.Id);
      if(foundCompanyIndex !== -1){
        this.companyOptions[foundCompanyIndex] = company;
      }
      this.getContactsByCompanyId(event.Id);
      this.locationOptions = event.GoodsReceivers ? event.GoodsReceivers : [];
      this.leadData.Equipment = [];
      this.leadData.Location = {};
      this.leadData.Contact = {Id:"",Name:"",Email:"",Phone:"",Landline:""};
    },

    getContactsByCompanyId(companyId){
      this.$store.commit('setLoading',true);
      this.$store.dispatch("getRequest","getContactsByCompanyId&query=" + companyId).then(resp => {
        this.$store.commit('setLoading',false);
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.contactOptions = body.map(contact => ({ Name: contact.ContactName,
            Id:contact.ContactId,
            Email:contact.ContactEmail,
            Phone:contact.OptionalParams ? contact.OptionalParams.Mobile ? contact.OptionalParams.Mobile : "" : "",
            Landline:contact.OptionalParams ? contact.OptionalParams.Landline ? contact.OptionalParams.Landline : "" : ""}));
        }
      });
    },

    getLocationsByCompanyId(companyId){
      this.$store.dispatch("getRequest","getGoodsReceiversByCompanyId&query=" + companyId).then(resp => {
        this.$store.commit('setLoading',false);
        if(resp && resp.statusCode === 200){
          this.locationOptions = JSON.parse(resp.body);
        }
      });
    },

    getCompanyOptionLabel(props){
      return props.Name + " '" + (props.City ? props.City : "") + "' (" + props.CompanyNumber + ")";
    },

    setLeadSupplier(event) {
      this.leadData.Supplier = event.Name;
      this.leadData.SupplierId = event.Id;
    },

    setContact(event){
      this.leadData.Contact = event;
      const foundContactInMembers = this.leadData.Members.find(member => member.Id === event.Id);
      if(!foundContactInMembers){
        this.leadData.Members.push({Id:event.Id,Name:event.Name,type:"Contact"});
      }
    },

    /**
     * In case we do not know the company we set leadCompany to our input and empty the id
     * so when we save the edit, we check if the company is new and give a new id or already exists.
     * @param event
     */
    setCompany(event){
      this.leadData.Company = {
        Id: '',
        Name:event,
        CompanyNumber:'',
        City: '',
      };
    },

   
    uploadFiles(event) {
      const correspondence = this.createCorrespondence();
      
      const processFile = (file, fileName, filePath) => {
        if (correspondence.Text !== "") correspondence.Text += ', ';
        correspondence.Text += ' ' + fileName;
        correspondence.Files.push({ path: filePath, name: fileName });

        return this.s3UploadFile(file, filePath).then(() => {
          this.files.push({
            Name: fileName,
            Type: this.getFileType(file.name),
            LastModified: new Date(file.lastModified).toLocaleString(),
            Size: (file.size / (1024 * 1024)).toFixed(2) + " MB",
            Key: filePath,
          });
        }).catch((err) => {
          console.log(err);
        });
      };

      const promises = [];

      for (let file of event.files) {
        this.$store.commit('setLoading', true);
        let filePath = 'sales/leadsAndProjects/' + this.leadData.Id + '/correspondences/' + correspondence.Id + '/';
        let fileName = this.getFileName(file.name);

        if (file.type.includes('message')) {
          const emailPromise = this.getEmailText(file).then(() => {
            fileName = ' ' + this.$t('date') + ': ' + this.emailContent.date + ' ' + fileName;
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
            return processFile(file, fileName, filePath); // Process file after getting email content
          });
          promises.push(emailPromise);
        }else if (file.name.endsWith('.msg')) {
          const emailPromise = this.readMsgFile(file).then((emailData) => {
            fileName = ' ' + `${this.$t('date')}: ${emailData.date}` + ' ' + fileName;
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
            return processFile(file, fileName, filePath)
          });
          promises.push(emailPromise);
         } else {
          fileName = this.sanitizeFileName(fileName);
          filePath = filePath + fileName.trim();
          const filePromise = processFile(file, fileName, filePath); // Process regular files
          promises.push(filePromise);
        }
      }

      // Use Promise.all to wait until all file uploads have completed
      Promise.all(promises).then(() => {
        // Now update correspondence after all files have been processed
        correspondence.Text = this.$t('files') + ': ' + correspondence.Text + ' ' + this.$t('uploaded');
        this.leadData.Correspondence = [...this.leadData.Correspondence, correspondence];
        this.$store.commit('setLoading', false);
      }).catch((err) => {
        console.log(err);
        this.$store.commit('setLoading', false);
      });
    },

    getEmailText(file){
      return new Promise((resolve) => {
        const reader = new FileReader();
        
        // Read the file as text or binary, depending on your email file format (.eml or .msg)
        reader.onload = (e) => {
          const emailContent = e.target.result;
          this.emailContent = this.parseEmailContent(emailContent);
          resolve();
        };
        
        // Read as text (suitable for .eml files)
        reader.readAsText(file);
      });
    },

    async readMsgFile(file) {
      return new Promise((resolve) => {
        const fileReader = new FileReader();

        // When the file is loaded, use msg-parser to parse it
        fileReader.onload = async (e) => {
          const arrayBuffer = e.target.result;

          try {
            const msgReader = new MSGReader(arrayBuffer);
            const fileData = msgReader.getFileData();

            const emailData = {
            date: fileData.creationTime.replace(/,/g, "") || 'Unknown',
          };

          resolve(emailData)

          } catch (err) {
            console.error('Error parsing .msg file:', err);
          }
        };

        // Read the file as an ArrayBuffer
        fileReader.readAsArrayBuffer(file);
      });
    },

    getEmailFromSubject(emailString){
      return emailString.match(/<([^>]+)>/)[1];
    },

    parseEmailContent(emailContent) {
      // Parsing .eml content (simple approach for text-based emails)
      const emailLines = emailContent.split("\n");

      let date = "";

      emailLines.forEach(line => {
        if (line.startsWith("Date:")) {
          date = line.replace("Date:", "").replace(/,/g, "").replace(/\s\+.*/, "").trim();
        }
      });

      return {
        date
      }
    },

    createCorrespondence(){
      return {
        "Phase": {Number:"10",Name:'Projektanfrage'},
        "Text":"",
        "Id": this.createUuidv4(),
        "Author": this.username,
        "Members":[],
        "VisibleTo": 'All',
        "Unix": new Date().getTime(),
        "Files": [],
        "Type": "Beitrag",
      }
    },

    deleteFile(file){
      this.$confirm.require({
        header: this.$t('swal.deleteFileHeader'),
        message: this.$t('swal.deleteFileText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('deleteS3Object',file.Key).then(() => {
            this.files = this.files.filter(foundFile => foundFile.Key !== file.Key);
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.fileDeleted'), life: 3000 });
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    checkIfFilesNotUploaded(){
      if(this.$refs.fileUpload.files.length > 0) {
        this.$confirm.require({
          header: this.$t('swal.submitWithoutUploadingHeader'),
          message: this.$t('swal.submitWithoutUploadingText'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.submitEdit();
          },
          reject: () => {
            this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
          }
        });
      }else{
        this.submitEdit();
      }
    },

    /**
     * After validating all inputs, we set the LeadTagContainer & iterate through all customLists.
     * We check if every customList is optional and has not been found in LeadTags.
     * If both criteria are set, we add a new key with the missing tag and set it to an empty string.
     * In the end we emit the leadData with submit-edit-lead.
     **/
    submitEdit(){
      if(this.hasKnownResponsible && this.leadData.Company.Id === ""){
        this.leadData.Company = {};
      }

      this.v$.$validate();
      if(!this.v$.$error){
        this.leadData.LeadTagContainer = this.selectedTagContainer;

        this.customLists.forEach(customList => {
          const foundTag = Object.keys(this.leadData.LeadTags).filter(tag => tag === customList.Id)[0];
          if(customList.isOptional && !foundTag){
            this.leadData.LeadTags[customList.Id] = "";
          }
        });

        // in case contact is not selected or company is set as an interest.
        if(this.leadData.Contact && this.leadData.Contact.Id){
          this.leadData.ContactId = this.leadData.Contact.Id;
        }
        if(this.leadData.Company.Id) this.leadData.CompanyId = this.leadData.Company.Id;
        this.leadData.ResponsibleId = this.leadData.Responsible.Id;

        this.$emit('submit-edit-lead', this.leadData);
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('errorKeysNeeded') + this.getErrors(), life: 5000 });
      }
    },

    getErrors(){
      let errorList = "";
      for(let error of this.v$.$errors){
        if(errorList !== "" && !errorList.includes(this.$t('tags'))){
          errorList = errorList + ", ";
        }
        switch(error.$property){
          case "Title":
            errorList = errorList + ' ' + this.$t('title');
            break;
          case "Responsible":
            errorList = errorList + ' ' + this.$t('responsible');
            break;
          case "Company":
            errorList = errorList + ' ' + this.$t('company')
            break;
          case "Description":
            errorList = errorList + ' ' + this.$t('description');
            break;
          case "Prio":
            errorList = errorList + ' ' + this.$t('prio');
            break;
          case "selectedTagContainer":
            errorList = errorList + ' ' + this.$t('classification');
            break;
          default:
            if(!errorList.includes(this.$t('tags'))){
              errorList = errorList + ' ' + this.$t('tags');
            }
            break;
        }
      }

      return errorList;
    },

    closePopup(leadData){
      this.leadData = leadData
      this.emitCloseReminders();
    },

    emitCloseReminders(){
      this.$emit('close-reminders');
    },

    getFiles(){
      //Needed for older leads to get uploaded files.
      this.getS3Objects( 'sales/leadsAndProjects/' + this.leadData.Id + '/attachments/').then(res => {
        this.files.push(...res);
      });

      //With the new logic we add a correspondence with all files per upload of bundled files in add/editLead.
      // Old leads won't have Correspondences, so we first check if it exists and if its filled.
      if(this.leadData.Correspondence && this.leadData.Correspondence.length > 0){
        for(let correspondence of this.leadData.Correspondence){
          this.getS3Objects( 'sales/leadsAndProjects/' + this.leadData.Id + '/correspondences/' + correspondence.Id + '/').then(res => {
            this.files.push(...res);
          });
        }
      }
    },

    setTagContainer(){
      if(this.leadData.LeadTagContainer) {
        if (this.leadData.LeadTagContainer.id) {
          this.selectedTagContainer = this.leadData.LeadTagContainer;
        }
      }
    },

    getSupplierOptions(){
      this.$store.dispatch("getRequest","getSuppliers").then(resp => {
        const body = JSON.parse(resp.body);
        this.supplierOptions = body.Items.map((item) => ({Name:item.Name, Id:item.Id}));
      });
    },

    setEditLeadComponent(newVal){
      this.getFiles();

      this.companyOptions.push(this.leadData.Company);

      this.getLocationsByCompanyId(this.leadData.Company.Id);

      this.hasEquipment = newVal.Equipment.length > 0;

      this.hasKnownResponsible = newVal.Company.Id.length > 0 || this.leadData.Company.Id === '-';

      this.setTagContainer();

    }
  },

  mounted(){
    this.setEditLeadComponent(this.leadData);
    this.getSupplierOptions();
    
    if(this.disabled) this.getContactsOptions();

    this.leadSupplier = {Name: this.leadData.Supplier, Id: this.leadData.SupplierId};
  },

  watch: {
    leadDataProp(newVal) {
      if (newVal) {
        this.files = [];
        this.leadData = newVal;

        this.setEditLeadComponent(newVal);
      }
    },
  }
}
</script>

<style scoped>
</style>