<template>
  <div class="mr-3 ml-3">
    <InputText class="w-full" autofocus v-model="filterValue" @keyup="filterSearchValue" :placeholder ="placeholder" />
    <div v-if="showTable" class="mt-2">
      <div v-if="showTimespan">
        <div style="display: flex; margin-bottom:10px; justify-content:space-between;
          align-items:center; gap:10px">
          <div>
            {{$t('timespan') + ': '}}
            {{new Date(closedStartUnix).toLocaleDateString() + ' - '}}
            {{new Date().toLocaleDateString()}}
          </div>
          <div>
            <PrimeButton
              outlined
              rounded
              severity="contrast"
              @click="loadNextMonths">
              {{ $t('loadNext6Months') }}
            </PrimeButton>
          </div>
        </div>
      </div>
      <SearchableSelectRowTable :search-option="searchOption"
                                :optionsList="optionsList"
                                @selection-changed="emitSelectionChanged"/>
    </div>
    <div v-else class="dropdown">
      <div v-for="option in optionsList" :key="option" class="dropdown-item">
        <span v-if="searchOption === $t('company')" class="innerWrapperDropdown" @click="emitSelectionChanged(option)">
          {{ option.Name }} ({{ option.CompanyNumber }})
        </span>
      </div>
    </div>
  </div>

</template>

<script>
import InputText from "primevue/inputtext";
import SearchableSelectRowTable
  from "@/components/global-components/searchable-select-row/searchable-select-row-table/SearchableSelectRowTable";
import PrimeButton from "primevue/button";

export default {
  name: "SearchableSelectRow",
  components:{SearchableSelectRowTable, InputText, PrimeButton},
  emits: ['selectionChanged','resetFilterValue'],
  props:{
    searchOption:String,
    placeholder:String,
    resetFilterValue:Boolean,
    showTable:Boolean,
    showTimespan:Boolean,
  },
  data(){
    return{
      closedStartUnix:(new Date().getTime()).toFixed() - 15552000000,
      closedEndUnix:(new Date().getTime()).toFixed(),
      filterValue:"",
      optionsList:[],
    }
  },

  methods:{
    emitSelectionChanged(item){
      this.$emit('selection-changed',item);
      this.filterValue = "";
      this.optionsList = [];
    },

    filterSearchValue(){
      clearTimeout(this.filterSearchValue.timeoutId);

      if (this.filterValue.length > 2) {
        this.filterSearchValue.timeoutId = setTimeout(() => {
          this.optionsList = [];
          this.performSearch();
        }, 1000);
      } else {
        this.optionsList = [];
      }
    },

    performSearch(){
      this.$store.commit('setLoading', true);

      switch(this.searchOption){
        case this.$t('projects'):
          this.getProjects();
          break;
        case "ClosedProjects":
        case this.$t('closedProjects'):
          this.getClosedProjects();
          break;
        case this.$t('leads'):
          this.getLeads();
          break;
        case "ClosedLeads":
        case this.$t('closedLeads'):
          this.getClosedLeads();
          break;
        case this.$t('person'):
          this.getPersons();
          break;
        case this.$t('company'):
          this.getCompanies();
          break;
        case this.$t('offer'):
          this.getOffers();
          break;
        default:
          this.$store.commit('setLoading', false); // Handle the case where searchOption is invalid
          break;
      }
    },

    loadNextMonths(){
      this.closedStartUnix = this.closedStartUnix - 15552000000;
      this.closedEndUnix = this.closedEndUnix - 15552000000;
      this.performSearch();
    },

    /**
     * Gets all projects that includes the filterValue in their title.
     */
    getProjects(){
      let lastKey = null;
        const fetchProjects = () => {
          const params = lastKey 
            ? "continueSearchProjectsDB&query=" + [this.filterValue, lastKey.ID, lastKey.Created, this.closedStartUnix, this.closedEndUnix] 
            : "searchProjectsDB&query=" + [this.filterValue, this.closedStartUnix, this.closedEndUnix];
          
          this.$store.dispatch("getRequest", params).then(resp => {
            if (resp && resp.statusCode === 200) {
              const body = JSON.parse(resp.body);
              this.optionsList = [...this.optionsList, ...body.Items];
              lastKey = body['LastEvaluatedKey'] || null;

              if (lastKey) { // if more data exists inside the timespan we fire the request again
                fetchProjects();
              } else {
                this.$store.commit('setLoading', false);
              }
            } else {
              this.$store.commit('setLoading', false);
            }
          }).catch(error => {
            console.log('Error fetching projects: ', error);
            this.$store.commit('setLoading', false);
          });
        };

        fetchProjects(); 
    },

    getClosedProjects(){
      let lastKey = null;
        const fetchClosedProjects = () => {
          const params = lastKey 
            ? "continueSearchClosedProjectsDB&query=" + [this.filterValue, lastKey.ID, lastKey.Created, this.closedStartUnix, this.closedEndUnix] 
            : "searchClosedProjectsDB&query=" + [this.filterValue, this.closedStartUnix, this.closedEndUnix];
          
          this.$store.dispatch("getRequest", params).then(resp => {
            if (resp && resp.statusCode === 200) {
              const body = JSON.parse(resp.body);
              this.optionsList = [...this.optionsList, ...body.Items];
              lastKey = body['LastEvaluatedKey'] || null;

              if (lastKey) { // if more data exists inside the timespan we fire the request again
                fetchClosedProjects();
              } else {
                this.$store.commit('setLoading', false);
              }
            } else {
              this.$store.commit('setLoading', false);
            }
          }).catch(error => {
            console.log('Error fetching closed projects: ', error);
            this.$store.commit('setLoading', false);
          });
        };

        fetchClosedProjects(); 
    },

    getLeads(){
      let lastKey = null;
        const fetchLeads = () => {
          const params = lastKey 
            ? "continueSearchLeadsDB&query=" + [this.filterValue, lastKey.ID, lastKey.Unix, this.closedStartUnix, this.closedEndUnix] 
            : "searchLeadsDB&query=" + [this.filterValue, this.closedStartUnix, this.closedEndUnix];
          
          this.$store.dispatch("getRequest", params).then(resp => {
            if (resp && resp.statusCode === 200) {
              const body = JSON.parse(resp.body);
              this.optionsList = [...this.optionsList, ...body.Items];
              lastKey = body['LastEvaluatedKey'] || null;

              if (lastKey) { // if more data exists inside the timespan we fire the request again
                fetchLeads();
              } else {
                this.$store.commit('setLoading', false);
              }
            } else {
              this.$store.commit('setLoading', false);
            }
          }).catch(error => {
            console.log('Error fetching leads: ', error);
            this.$store.commit('setLoading', false);
          });
        };

        fetchLeads(); 
    },

    getClosedLeads(){
      let lastKey = null;
        const fetchClosedLeads = () => {
          const params = lastKey 
            ? "continueSearchClosedLeadsDB&query=" + [this.filterValue, lastKey.ID, lastKey.Unix, this.closedStartUnix, this.closedEndUnix] 
            : "searchClosedLeadsDB&query=" + [this.filterValue, this.closedStartUnix, this.closedEndUnix];
          
          this.$store.dispatch("getRequest", params).then(resp => {
            if (resp && resp.statusCode === 200) {
              const body = JSON.parse(resp.body);
              this.optionsList = [...this.optionsList, ...body.Items];
              lastKey = body['LastEvaluatedKey'] || null;

              if (lastKey) { // if more data exists inside the timespan we fire the request again
                fetchClosedLeads();
              } else {
                this.$store.commit('setLoading', false);
              }
            } else {
              this.$store.commit('setLoading', false);
            }
          }).catch(error => {
            console.log('Error fetching closed leads: ', error);
            this.$store.commit('setLoading', false);
          });
        };

        fetchClosedLeads();
    },

    getPersons(){
      this.$store.dispatch("getRequest","getContactsBySearchValue&query=" + this.filterValue).then(resp => {
        if(resp && resp.statusCode === 200){
          this.optionsList = JSON.parse(resp.body).map(contact => ({
            Department:contact.OptionalParams && contact.OptionalParams.Department,
            Position:contact.OptionalParams && contact.OptionalParams.Position,
            Retired:contact.OptionalParams && contact.OptionalParams.Retired ? this.$t('yes') : this.$t('no'),
            Name:contact.ContactName,
            Customer:contact.CompanyName,
            Id:contact.ContactId,
            Type:"Contact"
          }));
          for(let responsible of this.$store.getters.responsibles){
            if (responsible.FriendlyName.toLowerCase().includes(this.filterValue.toLowerCase())) {
              this.optionsList.push({
                Department:responsible.OptionalParams && responsible.OptionalParams.Department,
                Position:responsible.OptionalParams && responsible.OptionalParams.Position,
                Retired:responsible.OptionalParams && responsible.OptionalParams.Retired ? this.$t('yes') : this.$t('no'),
                Name:responsible.FriendlyName,
                Customer:responsible.CompanyName,
                Id:responsible.User,
                Type:"User"
              });
            }
          }

          this.optionsList.sort((a, b) => {
            if (a['Name'] < b['Name']) return -1;
            if (a['Name'] > b['Name']) return 1;
          });
        }

        this.$store.commit('setLoading', false);
      });
    },

    getCompanies(){
      this.$store.dispatch("getRequest","getCompaniesBySearchValue&query=" + this.filterValue).then(resp => {
        if(resp && resp.statusCode === 200){
          this.optionsList = JSON.parse(resp.body);
        }
        this.$store.commit('setLoading', false);
      });
    },
    getOffers(){
      this.optionsList = this.$store.getters.offers;
      this.$store.commit('setLoading', false);
    }
  },
  watch:{
    /**
     * If we reopen leads / projects, we reset the filterValue and optionsList, so we can't click the currently changed lead / project.
     */
    resetFilterValue(){
      if(this.resetFilterValue){
        this.filterValue = "";
        this.optionsList = [];
        this.$emit('reset-filter-value');
      }
    },

    searchOption(){
      this.filterSearchValue();
      this.closedStartUnix = (new Date().getTime()).toFixed() - 15552000000;
      this.closedEndUnix = (new Date().getTime()).toFixed();
    }
  }
}
</script>

<style scoped>

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 197px;
  overflow-y: auto;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  z-index: 1000;
}

.dropdown-item {
  display: block;
}

.innerWrapperDropdown {
  padding: 15px;
  border-bottom: 1px solid lightgrey;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.innerWrapperDropdown:hover {
  background-color: lightgrey;
  cursor: pointer;
  border-radius: 2px;
}

</style>