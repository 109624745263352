<template>
  <div v-if="showCompleteHeader" class="flex justify-content-between">
    <div class="flex align-items-start flex-column md:flex-row">
      <Avatar class="mr-5 mb-3 lg:mb-0 text-4xl" style="height: 90px;width:90px; min-width: 90px" :label="contact.ContactName.substring(0,2).toUpperCase()" shape="circle" />
      <div>
        <span class="text-900 font-medium text-3xl">{{contact.OptionalParams?.Status || contact.OptionalParams?.status}} {{contact.ContactName}}</span>
        <div class="flex align-items-center flex-wrap text-sm">
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('company')}}</span>
            <div class="text-white-700 cursor-pointer" @click="goToCompanyDashboard">{{contact.CompanyName}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('email')}}</span>
            <div>{{contact.ContactEmail}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('landline')}}</span>
            <div>{{contact.OptionalParams && contact.OptionalParams.Landline ?
                   contact.OptionalParams.Landline : "-"}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('phone')}}</span>
            <div>{{contact.OptionalParams && contact.OptionalParams.Mobile ?
                contact.OptionalParams.Mobile : "-"}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">Position</span>
            <div>{{contact.OptionalParams && contact.OptionalParams.Position ?
                   contact.OptionalParams.Position : "-"}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('department')}}</span>
            <div>{{contact.OptionalParams && contact.OptionalParams.Department ?
                   contact.OptionalParams.Department : "-"}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('title')}}</span>
            <div>{{contact.OptionalParams && contact.OptionalParams.Title ?
                   contact.OptionalParams.Title : "-"}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{ $t('retired') }}</span>
            <div>{{contact.OptionalParams && contact.OptionalParams.Retired ?
                $t('yes') : $t('no')}}</div>
          </div>
          <div v-if="contact.TagContainer" class="mr-5 mt-3">
            <span class="font-medium text-500">{{this.$t('classification')}}</span>
            <div>{{getContactTagContainer()}}</div>
          </div>
          <div v-for="tag of contact.Tags" class="mr-5 mt-3" :key="tag">
            <span class="font-medium text-500">{{tag.labelName}}</span>
            <div style="max-width: 250px; overflow-x: auto; white-space: nowrap;">{{tag.selectedValue.toString()}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{this.$t('lastContact')}}</span>
            <div>{{lastContact}}</div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <PrimeButton v-if="contactIsFavorite" icon="pi pi-star-fill" severity="contrast" plain text @click="unfavorisePerson" />
      <PrimeButton v-else icon="pi pi-star" severity="contrast" plain text @click="favorisePerson" />
      <PrimeButton icon="pi pi-angle-up" severity="contrast" rounded @click="setShowCompleteHeader(false)" />
      <PrimeButton v-if="showDashboardViewer" icon="pi pi-angle-right" severity="contrast" rounded @click="setShowDashboardViewer(false)" />
      <PrimeButton v-else icon="pi pi-angle-left" severity="contrast" rounded @click="setShowDashboardViewer(true)" />
    </div>
  </div>

  <div v-else>
    <div class="flex justify-content-between flex-column md:flex-row">
      <div>
        <span class="text-900 font-medium text-3xl">{{contact.ContactName}}</span>
      </div>
      <div>
        <PrimeButton v-if="contactIsFavorite" icon="pi pi-star-fill" severity="contrast" plain text @click="unfavorisePerson" />
        <PrimeButton v-else icon="pi pi-star" severity="contrast" plain text @click="favorisePerson" />
        <PrimeButton icon="pi pi-angle-down" severity="contrast" rounded @click="setShowCompleteHeader(true)" />
        <PrimeButton v-if="showDashboardViewer" icon="pi pi-angle-right" severity="contrast" rounded @click="setShowDashboardViewer(false)" />
        <PrimeButton v-else icon="pi pi-angle-left" severity="contrast" rounded @click="setShowDashboardViewer(true)" />
      </div>
    </div>
  </div>
</template>

<script>
import PrimeButton from "primevue/button";
import Avatar from "primevue/avatar";

export default {
  name: "ContactDashboardHeader",
  emits:['showCards', 'showDashboardViewer'],
  components:{PrimeButton, Avatar},
  data(){
    return{
      favorisedPersons:[],
      showCompleteHeader:true,
    }
  },
  props:{
    contact:Object,
    lastContact:String,
    showDashboardViewer:Boolean,
  },
  computed:{
    contactIsFavorite(){
      if (localStorage.getItem("favorisedPersons") !== null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.favorisedPersons = JSON.parse(localStorage.getItem("favorisedPersons"));
      }
      if(this.favorisedPersons.length > 0){
        for(let favorisedPerson of this.favorisedPersons){
          if(favorisedPerson.Id === this.contact.ContactId){
            return true;
          }
        }
      }
      return false;
    },

    tagContainers(){
      return this.$store.getters.allTagContainers;
    }
  },
  methods:{
    favorisePerson(){
      const person = {
        Id:this.contact.ContactId,
        FriendlyName:this.contact.ContactName,
        CompanyName:this.contact.CompanyName,
        Position:this.contact.OptionalParams && this.contact.OptionalParams.Position,
        Department:this.contact.OptionalParams && this.contact.OptionalParams.Department,
        Type:'Contact',
      }
      this.favorisedPersons.push(person);

      window.localStorage.setItem("favorisedPersons", JSON.stringify(this.favorisedPersons));
    },

    unfavorisePerson(){
      this.favorisedPersons = this.favorisedPersons.filter(person => person.Id !== this.contact.ContactId);

      window.localStorage.setItem("favorisedPersons", JSON.stringify(this.favorisedPersons));
    },

    setShowCompleteHeader(trueOrFalse){
      this.showCompleteHeader = trueOrFalse;
      this.$emit('show-cards',trueOrFalse);
    },

    setShowDashboardViewer(trueOrFalse){
      this.$emit('show-dashboard-viewer',trueOrFalse);
    },

    getContactTagContainer(){
      const found = this.tagContainers.find(tagContainer => tagContainer.id === this.contact.TagContainer);
      return found ? found.ContainerName : '-';
    },

    goToCompanyDashboard(){
      this.$router.push({
        path:"/companies/dashboard/" + this.contact.CompanyId,
        params:this.contact.CompanyId
      });
    }
  },
}
</script>

<style scoped>

</style>