<template>
    <div v-if="show" class="slider" id="divLoaderParent">
      <div class="line"></div>
      <div class="subline inc"></div>
      <div class="subline dec"></div>
    </div>

</template>

<script>
export default {
  name: "LoaderComponent",
  props:{
    show:Boolean
  }
}
</script>

<style scoped>

.slider{
  position:fixed;
  top:0;
  left:0;
  z-index: 100000;
  width:100%;
  height:100%;
  overflow-x: hidden;
}

.line{
  position:absolute;
  opacity: 0.4;
  background:white;
  width:150%;
  height:5px;
}

.subline{
  position:absolute;
  background:#4a8df8;
  height:5px;
}
.inc{
  animation: increase 2s infinite;
}
.dec{
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
  from { left: -5%; width: 5%; }
  to { left: 130%; width: 100%;}
}
@keyframes decrease {
  from { left: -80%; width: 80%; }
  to { left: 110%; width: 10%;}
}



</style>
