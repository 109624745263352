<template>
  <div v-if="contact" class="flex gap-2 h-full" style="overflow:hidden">
    <div class="flex flex-column flex-auto overflow-y-auto" :class="showDashboardViewer ? 'col-8' : 'col-12'">
      <div class="surface-section p-5" style="box-sizing:border-box;width:100%; margin-bottom:10px; position:sticky;top:0;background:var(--surface-0);z-index:10">
        <ContactDashboardHeader :contact="contact"
                                :last-contact="lastContact"
                                @show-cards="setShowCards"
                                :show-dashboard-viewer="showDashboardViewer"
                                @show-dashboard-viewer="setShowDashboardViewer" />
      </div>

      <div class="grid">
        <div class="col-12" ref="materialTable">
          <ContactMaterialTable :contact="contact"
                                :active-index="materialTableIndex"
                                :leads="leads"
                                :projects="projects"
                                :closed-leads="closedLeads"
                                :closed-projects="closedProjects"
                                :sales="sales"
                                :correspondences="correspondences"
                                :correspondences-length="correspondences.length"
                                :show-load-more-leads="leadsLastEvaluatedKey"
                                :show-load-more-projects="projectsLastEvaluatedKey"
                                :show-load-more-closed-leads="closedLeadsLastEvaluatedKey"
                                :show-load-more-closed-projects="closedProjectsLastEvaluatedKey"
                                :show-load-more-sales="salesLastEvaluatedKey"
                                @load-more-data="loadMoreData"
                                @row-clicked="setDashboardViewerData" />
        </div>
      </div>
    </div>

    <div v-if="showDashboardViewer" class="col-4" style="max-height:75vh;overflow-y:auto;">
      <DashboardViewer :selected-data="selectedData"
                       :data-type="dataType"
                       :contact-id="contactId"
                       :correspondence-type="$t('contact')" />
    </div>
  </div>
  <div v-else-if="showDoesntExist" class="result-empty">
    <span>{{$t('contactDoesntExist')}}</span>
  </div>
  <div v-else>
    <DashboardInitialLoader />
  </div>
</template>

<script>
import ContactDashboardHeader from "@/components/contact/contact-dashboard-header/ContactDashboardHeader";
// import ContactDashboardCards from "@/components/contact/contact-dashboard-cards/ContactDashboardCards";
import ContactMaterialTable from "@/components/contact/contact-material-table/ContactMaterialTable";
import DashboardViewer from "@/components/global-components/dashboard-viewer/DashboardViewer";
import DashboardInitialLoader from "@/components/global-components/dashboard-initial-loader/DashboardInitialLoader";

export default {
  name: "ContactDashboard",
  components: {
    DashboardViewer,
    ContactMaterialTable,
    //ContactDashboardCards,
    ContactDashboardHeader,
    DashboardInitialLoader
  },

  created() {
    // Getting the current person id, if we reload the site inside the dashboard.
    const routerParams = this.$router.currentRoute.value.params;
    this.contactId = routerParams.Id;
  },

  data(){
    return{
      contactId:"",
      materialTableIndex:0,
      showCards:true,
      lastContact:"",
      showDashboardViewer:false,
      selectedData:null,
      dataType:"",
      leads:[],
      projects:[],
      leadsLastEvaluatedKey:null,
      projectsLastEvaluatedKey:null,
      closedLeads:[],
      closedProjects:[],
      closedLeadsLastEvaluatedKey:null,
      closedProjectsLastEvaluatedKey:null,
      sales:[],
      salesLastEvaluatedKey:null,
      showDoesntExist: false,
    }
  },
  computed:{
    contact(){
      return this.$store.getters.userDashboard;
    },

    correspondences(){
      const leads = this.$store.getters.leads;
      const projects = this.$store.getters.projects;
      let src = [];
      let data = [...leads, ...projects];
      if(this.closedLeads && this.closedProjects){
        data = [...data, ...this.closedLeads, ...this.closedProjects];
      }

      for(let object of data){
        const foundObject = src.find(currentObject => currentObject.Id === object.Id);
        if(!foundObject){
          if(object.Correspondence && object.Correspondence.length > 0){
            for(let correspondence of object.Correspondence){
              if(correspondence.Contact && correspondence.Contact.Id === this.contactId){
                src.push(correspondence);
                break;
              }
            }
          }
        }
      }

      return src;
    },
  },
  methods:{
    setShowDashboardViewer(trueOrFalse){
      this.showDashboardViewer = trueOrFalse;
      this.selectedData = null;
      this.dataType = "";
    },

    setDashboardViewerData(data){
      if(this.showDashboardViewer){
        this.selectedData = data.data;
        this.dataType = data.type;
      }else{
        if(data.type === 'Project'){
          this.$router.push({
            path:"/projects/dashboard/" + data.data.Id,
            params:data.data.Id
          });
        }else if(data.type === 'Offer' || data.type === 'Sale'){
          this.$router.push({
            path:"/projects/dashboard/" + data.data.ProjectId,
            params:data.data.Id
          });
        }else{
          this.showDashboardViewer = true;
          this.selectedData = data.data;
          this.dataType = data.type;
        }
      }
    },

    setMaterialTableIndex(index){
      this.materialTableIndex = index;
      const scrollContainer = this.$refs.materialTable;
      scrollContainer.scrollIntoView({
        behavior: 'smooth',
        block:'start'
      });
    },

    setShowCards(trueOrFalse){
      this.showCards = trueOrFalse;
    },

    loadMoreData(param){
      switch(param){
        case 'lead':
          this.getMoreLeads();
          break;
        case 'closedLead':
          this.getMoreClosedLeads();
          break;
        case 'project':
          this.getMoreProjects();
          break;
        case 'closedProject':
          this.getMoreClosedProjects();
          break;
        case 'sale':
          this.getMoreSales();
          break;
      }
    },

    getMoreLeads(){
      const id = this.leadsLastEvaluatedKey.Id;
      const created = this.leadsLastEvaluatedKey.Created;
      const contactId = this.leadsLastEvaluatedKey.ContactId;
      this.$store.dispatch("getRequest","getMoreLeadsByContact&query=" + [this.contactId, id, created, contactId]).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.leads = [...this.leads, ...body.Items];

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.leadsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },

    getMoreClosedLeads(){
      const id = this.closedLeadsLastEvaluatedKey.Id;
      const created = this.closedLeadsLastEvaluatedKey.Created;
      const contactId = this.closedLeadsLastEvaluatedKey.ContactId;
      this.$store.dispatch("getRequest","getMoreClosedLeadsByContact&query=" + [this.contactId, id, created, contactId]).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.closedLeads = [...this.closedLeads, ...body.Items];

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.closedLeadsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },

    getMoreProjects(){
      const id = this.projectsLastEvaluatedKey.Id;
      const created = this.projectsLastEvaluatedKey.Created;
      const contactId = this.projectsLastEvaluatedKey.ContactId;
      this.$store.dispatch("getRequest","getMoreProjectsByContact&query=" + [this.contactId, id, created, contactId]).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.projects = [...this.projects, ...body.Items];

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.projectsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },

    getMoreClosedProjects(){
      const id = this.closedProjectsLastEvaluatedKey.Id;
      const created = this.closedProjectsLastEvaluatedKey.Created;
      const contactId = this.closedProjectsLastEvaluatedKey.ContactId;
      this.$store.dispatch("getRequest","getMoreClosedProjectsByContact&query=" + [this.contactId, id, created, contactId]).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.closedProjects = [...this.closedProjects, ...body.Items];

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.closedProjectsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },

    getMoreSales(){
      const id = this.salesLastEvaluatedKey.Id;
      const salesDate = this.salesLastEvaluatedKey.SalesDate;
      const contactId = this.salesLastEvaluatedKey.ContactId;
      this.$store.dispatch("getRequest","getMoreSalesByContact&query=" + [this.contactId, id, salesDate, contactId]).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.sales = [...this.sales, ...body.Items];

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.salesLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },

    getContactById(){
      this.$store.dispatch("getRequest","getContactById&query=" + this.contactId).then(resp => {
        if(resp && resp.statusCode === 200 && resp.body && resp.body !== 'null'){
          const contact = JSON.parse(resp.body);
          this.$store.commit('setUserDashboard',contact);
          this.$store.commit('setShouldReloadDashboard', false);
          this.contactNotes = contact.OptionalParams && contact.OptionalParams.Notes ? contact.OptionalParams.Notes : '';
          localStorage.setItem('lastClickedPersonDashboard',JSON.stringify({
            Id:contact.ContactId,
            Name:contact.ContactName,
            Type:'Contact',
          }));
        }else{
          this.showDoesntExist = true;
        }
      });
    },

    getData(){
      this.$store.dispatch("getRequest","getLeadsByContact&query=" + this.contactId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.leads = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.leadsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });

      this.$store.dispatch("getRequest","getProjectsByContact&query=" + this.contactId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.projects = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.projectsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });

      this.$store.dispatch("getRequest","getSalesByContact&query=" + this.contactId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.sales = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.salesLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },

    getClosedData(){
      this.$store.dispatch("getRequest","getClosedLeadsByContact&query=" + this.contactId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.closedLeads = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.closedLeadsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });

      this.$store.dispatch("getRequest","getClosedProjectsByContact&query=" + this.contactId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.closedProjects = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.closedProjectsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },
  },
  mounted(){
    this.getContactById();
    this.getData();
    this.getClosedData();
  },
  watch:{
    //In case we switch routes by using the search function with the magnifying glass icon/button,
    //we need to set the new contactId and get the new contact data.
    $route(to){
      if(to.params.Id){
        if(this.contactId !== to.params.Id){
          this.contactId = to.params.Id;
          this.getContactById();
        }
      }
    },

    '$store.getters.shouldReloadDashboard': {
      handler() {
        // Fetch the data again when the flag changes
        if(this.$store.getters.shouldReloadDashboard){
          this.getContactById();
          this.getData();
          this.getClosedData();
        }
      },
    },

    correspondences:{
      handler(newVal) {
        // Find the latest option based on the timestamp
        if (newVal.length > 0) {
          const correspondences = this.contact && [...this.correspondences, ...this.contact.Correspondence.map(obj => ({Author:obj.sender, Unix: obj.timestamp}))];

          const lastContact = correspondences && correspondences.slice().sort((a, b) => b.Unix - a.Unix)[0];

          // returns the lastContact
          this.lastContact = lastContact && lastContact.Author + ' (' +
              new Date(Number(lastContact.Unix)).toLocaleDateString() + ')';
        }
      },
      immediate: true  // Trigger the watcher immediately on component creation

    }
  },
  unmounted() {
    this.$store.commit('setUserDashboard',null);
  }
}
</script>

<style scoped>
:deep(.p-tabview .p-tabview-panels){
  padding-left:0;
  padding-right:0;
}

.result-empty{
  font-size: 24px;
  text-align: center;
  margin-top: 300px;
  margin-bottom: 300px;
}
</style>