<template>
  <div class="w-full">
    <ejs-documenteditorcontainer ref="documenteditor" id="container" :serviceUrl="serviceUrl" :toolbarItems="items"
                                 height="700px" :enableToolbar="true" :enableEditor="true">
    </ejs-documenteditorcontainer>
  </div>

  <Popup :header="this.$t('addTask')" :modal="true" :visible="openAddTask" @close-popup="openAddTask = false"
         :show-save-button="true" @button-clicked="submitAddTaskPopup" styling="width:50%" >
    <AddTask ref="addTask" :project-id="projectId" :project-open="!isReadOnly" :visit-report-id="reportId"
             :members="members" :task-title="taskTitle" @close-popup="openAddTask = false" />
  </Popup>

  <Popup :header="this.$t('editTask')" :modal="true" :visible="openEditTask" @close-popup="resetTask"
         :show-save-button="!isReadOnly && selectedTask && selectedTask.Status === '10'" @button-clicked="submitEditTaskPopup" styling="width:50%" >
    <EditTask ref="editTask" v-if="selectedTask" :task-prop="selectedTask" :project-open="selectedTask.Status === '10'" :disabled="isReadOnly"
              :members="members" :project-id="projectId" @close-popup="resetTask" />
    <SelectTaskDropdown v-else :project-id="projectId" @emit-task="setSelectedTask" />
  </Popup>

  <Popup :header="this.$t('reportName')" :modal="true" :visible="openEnterReportName"
         :show-inline-message="true" :inline-message="$t('reportNameInfo')"
         :show-save-button="true" @button-clicked="saveName" @close-popup="openEnterReportName = false" styling="width:50%" >
    <InputText autofocus v-model="reportName" class="w-full" :class="v$.reportName.$error ? 'p-invalid' : ''" :placeholder ="$t('placeholder.name')" />

    <Dropdown class="w-full mt-2" v-model="contact" :options="contactOptions" optionLabel="Name"
              filter :placeholder="this.$t('placeholder.selectContact')" autoFilterFocus />
  </Popup>

</template>

<script>
import {DocumentEditorContainerComponent, Editor, Toolbar} from '@syncfusion/ej2-vue-documenteditor';
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";
import {PdfBitmap, PdfDocument, PdfPageOrientation, PdfPageSettings, SizeF} from '@syncfusion/ej2-pdf-export';
import Popup from "@/components/global-components/popup/Popup";
import AddTask from "@/components/tasks/add-task/AddTask";
import SelectTaskDropdown from "@/components/reports/visit-report/select-task-dropdown/SelectTaskDropdown";
import EditTask from "@/components/tasks/edit-task/EditTask";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import createFormFieldInfo from "@/mixins/create-form-field-info/createFormFieldInfo";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";

export default {
  name: "VisitReport",
  components:{
    EditTask,
    SelectTaskDropdown,
    "ejs-documenteditorcontainer":DocumentEditorContainerComponent,
    InputText,
    Dropdown,
    Popup,
    AddTask
  },
  mixins:[S3FileManager, createFormFieldInfo, globalComputedProperties],
  emits:['visit-report-saved', 'closeReport'],
  data () {
    return {
      items:[
        "Open", "Separator",
        "Undo", "Redo", "Image", "Table", "Hyperlink", "Separator",
        "Header", "Footer", "Find", "LocalClipboard", "FormFields"
      ],
      openAddTask:false,
      openEditTask:false,
      openEnterReportName:false,
      reportData:null,
      reportName:"",
      contact:null,
      selectedTask:null,
      serviceUrl: 'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/',
      taskTitle:"",
      contactOptions:[],
    }
  },

  props:{
    existingReportId:Boolean,
    isReadOnly:Boolean,
    showTaskButtons:Boolean,
    projectId:String,
    reportId:String,
    reportType:String,
    members:Array,
    salesId:String,
    companyId:String,
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      reportName: {required},
    }
  },

  provide: {
      //Inject require modules.
      DocumentEditorContainer: [Editor, Toolbar]
  },

  methods:{
    /**
     * First we get the edited report and see if it exists,
     * If not we get the newly saved default report from admin.
     * And if this one also doesn't exist, will we get the default stored
     * in our public-storage.
     */
    getReport(){
      this.getEditedReport().then(signedURL => {
        if(signedURL){
          fetch(signedURL,{}).then(response => response.blob())
            .then(blob => {
              const reader = new FileReader();

              // Add a listener to handle successful reading of the blob
              reader.addEventListener('load', () => {
                this.$refs.documenteditor.ej2Instances.documentEditor.open(reader.result);
              });

              // Start reading the content of the blob
              // The result should be a base64 data URL
              reader.readAsText(blob);
            })
            .catch(err =>{console.log(err)});
        }else{
          this.getNewDefaultReport().then(signedURL => {
            if(signedURL){
              fetch(signedURL,{}).then(response => response.blob())
                  .then(blob => {
                    const reader = new FileReader();

                    // Add a listener to handle successful reading of the blob
                    reader.addEventListener('load', () => {
                      this.$refs.documenteditor.ej2Instances.documentEditor.open(reader.result);

                      if (reader.result && this.reportData) {
                        this.createFormFieldInfo(this.$refs.documenteditor.ej2Instances.documentEditor, this.reportData);
                      }
                    });

                    // Start reading the content of the blob
                    // The result should be a base64 data URL
                    reader.readAsText(blob);
                  })
                  .catch(err =>{console.log(err)});
            }else{
              this.getDefaultWordFile().then(res => {
                if(res && res.type === 'text/plain'){
                  const reader = new FileReader();

                  // Add a listener to handle successful reading of the blob
                  reader.addEventListener('load', () => {
                    this.$refs.documenteditor.ej2Instances.documentEditor.open(reader.result);

                    if (reader.result && this.reportData) {
                      this.createFormFieldInfo(this.$refs.documenteditor.ej2Instances.documentEditor, this.reportData);
                    }
                  });

                  // Start reading the content of the blob
                  // The result should be a base64 data URL
                  reader.readAsText(res);
                }
              });
            }
          })
        }
      });
    },

    saveButton(){
      this.existingReportId ? this.saveDocument() : this.openEnterReportName = true;
    },

    saveName(){
      this.v$.$validate();
      if(!this.v$.$error) {
        this.saveDocument();
      }
    },

    /**
     * saves the document as a '.sfdt' file. After uploading the file we show a toast and emit an event,
     * that will close the popup.
     */
    saveDocument(){
      this.openEnterReportName = false;
      this.$refs.documenteditor.ej2Instances.documentEditor.saveAsBlob("Sfdt").then(blob =>{
        if (blob){
          // Create a File from the Blob
          const file = new File([blob], this.reportName + '.sfdt', { type: 'text/plain' });
          let folder;
          if(this.reportType === 'besuchsberichtSales'){
            folder = '/visit-reports/';
          }else {
            folder = '/phone-reports/';
          }
          let path, projectData, leadData;
          if(this.existingReportId){
            path = 'sales/leadsAndProjects/' + this.projectId + folder + this.reportId + ".sfdt";
          }else{
            path = 'sales/leadsAndProjects/' + this.projectId + folder + this.reportId + '_' +  this.$store.getters.shortUsername + '$' + this.reportName + ".sfdt";
            projectData = this.addProjectCorrespondence(path, file);
            leadData = this.addLeadCorrespondence(path, file);
          }

          this.s3UploadFile(file,path).then(()=>{
            if(projectData) this.$store.dispatch('updateCorrespondence',projectData).then(() => {});
            if(leadData) this.$store.dispatch('editLead',leadData).then(() => {});
            if(this.existingReportId) {
              this.$emit('visit-report-saved');
            }else{
              this.$emit('visit-report-saved');
              this.$emit('close-report');
            }
          }).catch(()=>{
            alert('Ein Fehler ist aufgetreten.');
          });
        }
      });
    },

    /**
     * Creates and adds a correspondence for the project, if we are adding a report
     * in the projectDashboard.
     * @param filePath
     * @param file
     * @returns {*}
     */
    addProjectCorrespondence(filePath, file){
      let foundProject = this.$store.getters.projects.find(project => project.Id === this.projectId);
      if(foundProject){
        const correspondence = {
          "Contact": this.contact,
          "Phase": foundProject.Phase,
          "Text": '"' + this.reportName + '" ' + this.$t('swal.reportCreated'),
          "Id": this.reportId,
          "Author": this.username,
          "Members": [],
          "VisibleTo": "All",
          "Unix": new Date().getTime(),
          "Files": [{path: filePath.toString(), name: file.name}],
          "Type": this.getReportType(),
        }

        foundProject.Correspondence = [...foundProject.Correspondence, correspondence]
      }

      return foundProject;
    },

    /**
     * Creates and adds a correspondence for the lead, if we are adding a report
     * in add- or editLead.
     * @param filePath
     * @param file
     * @returns {*}
     */
    addLeadCorrespondence(filePath, file){
      const foundLead = this.$store.getters.leads.find(lead => lead.Id === this.projectId);
      if(foundLead){
        const correspondence = {
          "Contact": this.contact,
          "Phase": {Number:"10",Name:'Projektanfrage'},
          "Text": '"' + this.reportName + '" ' + this.$t('swal.reportCreated'),
          "Id": this.reportId,
          "Author": this.username,
          "Members":[],
          "VisibleTo": "All" ,
          "Unix": new Date().getTime(),
          "Files": [{path: filePath.toString(), name: file.name}],
          "Type": this.getReportType(),
        }

        foundLead.Correspondence = [...foundLead.Correspondence, correspondence]
      }

      return foundLead;
    },

    getReportType(){
      let reportType = "";
      switch(this.reportType){
        case "besuchsberichtSales":
          reportType = "Besuchsbericht";
          break;
        case "telefonberichtSales":
          reportType = "Telefonbericht";
          break;
        default:
          reportType = this.reportType;
          break;
      }

      return reportType;
    },

    /**
     * We need the 'this' reference outside the loop, otherwise we will lose the reference to our documentedtior.
     */
    pdfExport(){
      let obj = this;
      let pdfdocument = new PdfDocument();
      let count = obj.$refs.documenteditor.ej2Instances.documentEditor.pageCount;
      obj.$refs.documenteditor.ej2Instances.documentEditor.documentEditorSettings.printDevicePixelRatio = 2;
      let loadedPage = 0;
      for (let i = 1; i <= count; i++) {
        setTimeout(() => {
          let format = 'image/jpeg';
          // Getting pages as image
          let image = obj.$refs.documenteditor.ej2Instances.documentEditor.exportAsImage(i, format);
          image.onload = function () {
            let imageHeight = parseInt(
                image.style.height.toString().replace('px', '')
            );
            let imageWidth = parseInt(
                image.style.width.toString().replace('px', '')
            );
            let section = pdfdocument.sections.add();
            let settings = new PdfPageSettings(0);
            if (imageWidth > imageHeight) {
              settings.orientation = PdfPageOrientation.Landscape;
            }
            settings.size = new SizeF(imageWidth, imageHeight);
            (section).setPageSettings(settings);
            let page = section.pages.add();
            let graphics = page.graphics;
            let imageStr = image.src.replace('data:image/jpeg;base64,', '');
            let pdfImage = new PdfBitmap(imageStr);
            graphics.drawImage(pdfImage, 0, 0, imageWidth, imageHeight);
            loadedPage++;
            if (loadedPage === count) {
              // Exporting the document as pdf
              pdfdocument.save(
                  (obj.$refs.documenteditor.ej2Instances.documentEditor.documentName === ''
                      ? 'sample'
                      : obj.$refs.documenteditor.ej2Instances.documentEditor.documentName) + '.pdf'
              );
            }
          };
        }, 500);
      }
    },

    /**
     * Opens a confirmation popup.
     * When accepting we get the existing report or create a completely new one if we don't have one saved.
     */
    resetReport(){
      this.$confirm.require({
        header: this.$t('swal.resetReportHeader'),
        message: this.$t('swal.resetReportText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.reportReset'), life: 3000 });
          this.getReport();
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        },
        styleClass:'reset-report-dialog'
      });
    },

    /**
     * First we get any selected text from our documentEditor.
     * If nothing is selected, taskTitle is "", otherwise we set taskTitle to our selection
     * and give it as a prop to the AddTask component.
     */
    setOpenAddTask(){
      let documentEditor = this.$refs.documenteditor.ej2Instances.documentEditor;
      this.taskTitle = documentEditor.selection.text;
      this.openAddTask = true;
    },

    setOpenEditTask(){
      this.openEditTask = true;
    },

    submitAddTaskPopup(){
      this.$refs.addTask.submitEdit();
    },

    submitEditTaskPopup(){
      this.$refs.editTask.submitEdit();
    },

    setSelectedTask(task){
      this.selectedTask = task;
    },

    resetTask(){
      this.selectedTask = null;
      this.openEditTask = false;
    },

    /**
     * Gets the edited report from our s3 path. If the file / path does not exist, we return null/undefined.
     * @returns {Promise<unknown>}
     */
    getEditedReport(){
      const storage = "storage-" + this.$store.getters.domainName;
      let folder = this.reportType === 'besuchsberichtSales' ? '/visit-reports/' : '/phone-reports/';
      const filePath = 'sales/leadsAndProjects/' + this.projectId + folder + this.reportId + ".sfdt";
      return new Promise((resolve) => {
        this.$store.dispatch('getRequest',"createSignedURL&query=" + [storage,filePath]).then((res)=>{
          const body = JSON.parse(res.body);
          resolve(body.URL);
        });
      });
    },

    // if we have not saved a report file yet, we will download the default
    // assembly report.
    getDefaultWordFile(){
      return new Promise((resolve)=>{
        fetch('https://public-storage-codevance.s3.eu-central-1.amazonaws.com/' + this.reportType + '_' + this.$store.getters.domainName + '.sfdt',{
        }).then(response => response.blob()).then(blob => {
          resolve(blob);
        }).catch(err =>{
          console.log(err);
        });
      })
    },

    /**
     * If we changed our default word file in admin, we have to get the new filePath for our report.
     * @returns {Promise<unknown>}
     */
    getNewDefaultReport(){
      return new Promise((resolve) => {
        this.$store.dispatch('getRequest',
            "createSignedURL&query="+ ["storage-" + this.$store.getters.domainName, "templates/" + this.reportType + ".sfdt"]
        ).then((result) => {
          const body = JSON.parse(result.body);
          resolve(body.URL);
        });
      });
    },

    getReportData(){
      this.$store.dispatch('getRequest',
          "getAssemblyReportData&query="+ [this.projectId, this.salesId]
      ).then((res) => {
        this.reportData = JSON.parse(res.body);
      })
    },

    getContactsByCompanyId(){
      this.$store.dispatch("getRequest","getContactsByCompanyId&query=" + this.companyId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.contactOptions = body.map(contact => ({ Name: contact.ContactName,
            Id:contact.ContactId,
            Email:contact.ContactEmail,
            Phone:contact.OptionalParams ? contact.OptionalParams.Mobile ? contact.OptionalParams.Mobile : "" : "",
            Landline:contact.OptionalParams ? contact.OptionalParams.Landline ? contact.OptionalParams.Landline : "" : ""}));
        }
      });
    },
  },
  mounted(){
    this.getReportData();
    this.getReport();

    if(!this.existingReportId){
      this.getContactsByCompanyId()
    }

    // property doesn't work in template (no idea why)
    if(this.isReadOnly) {
      this.$refs.documenteditor.ej2Instances.documentEditor.isReadOnly = true;
      this.$refs.documenteditor.ej2Instances.enableToolbar = false;
      this.$refs.documenteditor.ej2Instances.showPropertiesPane = false;
    }
  }
}
//TODO: Check if style does not contradict with sth else.
</script>

<style>
@import "../../../../node_modules/@syncfusion/ej2-vue-documenteditor/styles/tailwind-dark.css";

.e-dlg-container{
  z-index:10000!important;
}
</style>