<template>
  <div class="mb-2 mt-2"
       :class="isDesktop ? 'flex justify-content-between gap-2' : ''">
    <CustomLabel class="w-full"
                 :class="isDesktop ? '' : 'mb-2'"
                 :label="this.$t('personNumber')"
                 :input="optionalParams.PersonNumber">
      <InputText class="w-full"
                 v-model="optionalParams.PersonNumber"
                 :placeholder="this.$t('placeholder.personNumber')"
                 @update:modelValue="emitOptionalParams"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('position')"
                 :input="optionalParams.Position">
      <InputText class="w-full"
                 v-model="optionalParams.Position"
                 :placeholder="this.$t('placeholder.position')"
                 maxlength="100" />
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2"
       :class="isDesktop ? 'flex justify-content-between gap-2' : ''">
    <CustomLabel class="w-full"
                 :class="isDesktop ? '' : 'mb-2'"
                 :label="this.$t('salutations')"
                 :input="optionalParams.status">
      <Dropdown class="w-full"
                v-model="optionalParams.status"
                :options="statusOptions"
                filter
                :placeholder="this.$t('placeholder.salutations')"
                autoFilterFocus />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('title')"
                 :input="optionalParams.Title">
      <InputText class="w-full"
                 v-model="optionalParams.Title"
                 :placeholder="this.$t('placeholder.title')"
                 maxlength="100" />
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2"
       :class="isDesktop ? 'flex justify-content-between gap-2' : ''">
    <CustomLabel class="w-full"
                 :class="isDesktop ? '' : 'mb-2'"
                 :label="this.$t('mobile')"
                 :input="optionalParams.Mobile">
      <InputText class="w-full"
                 v-model="optionalParams.Mobile"
                 :placeholder="this.$t('placeholder.mobile')"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('landline')"
                 :input="optionalParams.Landline">
      <InputText class="w-full"
                 v-model="optionalParams.Landline"
                 :placeholder="this.$t('placeholder.landline')"
                 maxlength="100" />
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2"
       :class="isDesktop ? 'flex justify-content-between gap-2' : ''">
    <CustomLabel class="w-full"
                 :class="isDesktop ? '' : 'mb-2'"
                 :label="this.$t('department')"
                 :input="optionalParams.Department">
      <InputText class="w-full"
                 v-model="optionalParams.Department"
                 :placeholder="this.$t('placeholder.department')"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('building')"
                 :input="optionalParams.Building">
      <InputText class="w-full"
                 v-model="optionalParams.Building"
                 :placeholder="this.$t('placeholder.building')"
                 maxlength="100" />
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2"
       :class="isDesktop ? 'flex justify-content-between gap-2' : ''">
    <CustomLabel class="w-full"
                 :class="isDesktop ? '' : 'mb-2'"
                 :label="this.$t('birthday')"
                 :input="optionalParams.Birthday">
      <InputText class="w-full"
                 v-model="optionalParams.Birthday"
                 :placeholder="this.$t('placeholder.birthday')"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('notes')"
                 :input="optionalParams.Notes">
      <InputText class="w-full"
                 v-model="optionalParams.Notes"
                 :placeholder="this.$t('placeholder.notes')"
                 maxlength="100" />
    </CustomLabel>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";

export default {
  name: "AddContactOptionalParams",
  components:{
    InputText, Dropdown, CustomLabel
  },
  mixins:[globalComputedProperties],
  emits:['setOptionalParams'],
  props:{
    contactData:Object,
  },
  data(){
    return {
      optionalParams:{
        status: '',
        Title: '',
        FirstName: this.contactData.optionalParams.FirstName,
        LastName: this.contactData.optionalParams.LastName,
        Mobile: '',
        Landline: '',
        Department: '',
        Building:'',
        Birthday: '',
        Notes: '',
        Position: '',
        PersonNumber: '',
      }
    }
  },

  computed:{
    statusOptions(){
      return [
        this.$t('mr'), this.$t('mrs'), this.$t('diverse')
      ]
    }
  },

  methods:{
    emitOptionalParams(){
      this.$emit('set-optional-params',this.optionalParams);
    }
  }
}
</script>

<style scoped>

</style>