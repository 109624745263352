<template>
  <MaterialTable :data-key="'Id'"
                 :table-id="'personSales'"
                 :style="{'height': windowHeight}"
                 :data-prop="sales"
                 :columns="columns"
                 @row-selected="emitSaleClicked"
                 :has-paginator="true"
                 :rows-count="10"
                 :has-global-filter="true"
                 :show-toggle-column="true"
                 :filter-prop="filter"
                 :filter-options="filterOptions"
                 :sortable="true"
                 :has-removable-sort="true"
                 :key="tableKey"
                 :selection-mode="'single'"
                 :resizable-columns="true"
                 :show-load-more-data="showLoadMoreSales"
                 @load-more-data="loadMoreData"/>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "PersonMaterialTableSaleTab",
  components:{MaterialTable},
  emits:['saleClicked', 'loadMoreData'],
  data(){
    return{
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'CommissionNumber', header: this.$t('commissionNumber') },
        { field: 'CommissionValue', header: this.$t('commissionValue') },
        { field: 'Contact.Name', header: this.$t('contact') },
        { field: 'Responsible.Name', header: this.$t('responsible') },
        { field: 'OrderNumber', header: this.$t('orderNumber') },
        { field: 'ReferenceNumber', header: this.$t('referenceNumber') },
        { field: 'SalesDate', header: this.$t('salesDate') },
        { field: 'DeliveryDate', header: this.$t('deliveryDate') },
        { field: 'OfferName', header: this.$t('offer') },
        { field: 'Equipment', header: this.$t('equipments') },
        { field: 'CompetitionModel', header: this.$t('competitionModel') },
        { field: 'MachineModel', header: this.$t('machineModel') },
        { field: 'Discount', header: this.$t('discountPercentage') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CommissionNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CommissionValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Contact.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Responsible.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        OrderNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ReferenceNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        SalesDate: { value: null, matchMode: FilterMatchMode.IN },
        DeliveryDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Equipment: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CompetitionModel: { value: null, matchMode: FilterMatchMode.CONTAINS },
        MaschineModel: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Discount: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Title', 'CommissionNumber', 'CommissionValue', 'Contact.Name', 'Responsible.Name', 'OrderNumber', 'ReferenceNumber', 'OfferName', 'CompetitionModel', 'MaschineModel', 'Discount'],
      tableKey:0,
      windowHeight: '',
    }
  },
  props:{
    personId:String,
    sales:Array,
    showLoadMoreSales:Object,
  },
  methods:{
    emitSaleClicked(sale){
      const data = {data:sale, type:'Sale'};
      this.$emit('sale-clicked',data);
    },

    loadMoreData(){
      this.$emit('load-more-data','sale');
    }
  },
   
  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>