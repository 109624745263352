import {projectPhases} from "@/dummy-data/projectPhases";

export default {
    methods:{
        getStoreData(){
            return new Promise((resolve) => {
                this.$store.commit('setProjectPhases', projectPhases);
                this.$i18n.locale = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'de';

                this.$toast.add({ severity: 'info', summary: this.$t('fetching'), detail: this.$t('swal.fetchingData'), life: 3000 });

                const requests = [
                    this.getLatestLeads(),
                    this.getLatestProjects(),
                    this.getFavouriteProjects(),
                    this.getEquipments(),
                    this.getUsers(),
                    this.getTasks(),
                    this.getOffers(),
                    this.getSales(),
                    this.getCustomLists(),
                    this.getTagsContainers(),
                ];

                Promise.all(requests).then(() => {
                    this.$toast.add({ severity: 'success', summary: this.$t('done'), detail: this.$t('swal.dataUpToDate'), life: 3000 });
                    this.$store.commit('setLoading', false);
                    resolve();
                })
            })
        },

        getMoreData(){
            return new Promise((resolve) => {
                const requests = [
                    this.getMoreLeads(),
                    this.getMoreProjects(),
                    this.getMoreSales(),
                ];

                Promise.all(requests).then(() => {
                    this.$toast.add({ severity: 'success', summary: this.$t('done'), detail: this.$t('swal.dataUpToDate'), life: 3000 });
                    this.$store.commit('setLoading', false);
                    resolve();
                });
            });
        },

        getMoreClosedData(){
            return new Promise((resolve) => {
                const requests = [
                    this.getMoreClosedLeads(),
                    this.getMoreClosedProjects(),
                ];

                Promise.all(requests).then(() => {
                    this.$toast.add({ severity: 'success', summary: this.$t('done'), detail: this.$t('swal.dataUpToDate'), life: 3000 });
                    this.$store.commit('setLoading', false);
                    resolve();
                });
            });
        },

        getLatestLeads() {
            // Initial getLeads request
            return this.$store.dispatch("getRequest","getLeads").then(resp => {
                if (resp && resp.statusCode === 200) {
                    const body = JSON.parse(resp.body);
                    this.$store.commit('setLatestLeadsData', body.Items)

                    if (body.LastEvaluatedKey && body.Items.length > 0) {
                        this.$store.commit('setOpenLeadsLastEvaluatedKey',body.LastEvaluatedKey);
                    }
                }
            });
        },

        getMoreLeads(){
            const lastEvaluatedKey = this.$store.getters.openLeadsLastEvaluatedKey;
            if(lastEvaluatedKey && lastEvaluatedKey.Created && lastEvaluatedKey.Id){
                const id = Number(lastEvaluatedKey["Id"]);
                const created = Number(lastEvaluatedKey["Created"]);
                return this.$store.dispatch("getRequest","getMoreLeads&query=" +  [id, created]).then(resp => {
                    if (resp && resp.statusCode === 200) {
                        const body = JSON.parse(resp.body);
                        this.$store.commit('setLeadsData', body.Items);

                        if (body.LastEvaluatedKey && body.Items.length > 0) {
                            this.$store.commit('setOpenLeadsLastEvaluatedKey', body.LastEvaluatedKey);
                        }else{
                            this.$store.commit('setOpenLeadsLastEvaluatedKey', null);
                        }
                    }
                });
            }

        },

        getLatestProjects(){
            // Initial getProjects request
            return this.$store.dispatch("getRequest","getProjects").then(resp => {
                if (resp && resp.statusCode === 200) {
                    const body = JSON.parse(resp.body);
                    this.$store.commit('setLatestProjectsData', body.Items);
                    this.$store.commit('setShouldReloadDashboard',true);

                    if (body.LastEvaluatedKey && body.Items.length > 0) {
                        this.$store.commit('setOpenProjectsLastEvaluatedKey',body.LastEvaluatedKey);
                    }
                }
            });
        },

        getMoreProjects(){
            const lastEvaluatedKey = this.$store.getters.openProjectsLastEvaluatedKey;
            if(lastEvaluatedKey && lastEvaluatedKey.Created && lastEvaluatedKey.Id){
                const created = Number(lastEvaluatedKey["Created"]);
                const id = lastEvaluatedKey["Id"];
                return this.$store.dispatch("getRequest","getMoreProjects&query=" + [id, created]).then(resp => {
                    if (resp && resp.statusCode === 200) {
                        const body = JSON.parse(resp.body);
                        this.$store.commit('setOpenProjects', body.Items);

                        if (body.LastEvaluatedKey && body.Items.length > 0) {
                            this.$store.commit('setOpenProjectsLastEvaluatedKey', body.LastEvaluatedKey);
                        }else{
                            this.$store.commit('setOpenProjectsLastEvaluatedKey', null);
                        }
                    }
                });
            }
        },

        getSales(){
            // Initial getSales request
            return this.$store.dispatch("getRequest","getSales").then(resp => {
                if (resp && resp.statusCode === 200) {
                    const body = JSON.parse(resp.body);
                    this.$store.commit('setLatestSalesData', body.Items);

                    if (body.LastEvaluatedKey && body.Items.length > 0) {
                        this.$store.commit('setSalesLastEvaluatedKey', body.LastEvaluatedKey);
                    }
                }
            });
        },

        getMoreSales(){
            const lastEvaluatedKey = this.$store.getters.salesLastEvaluatedKey;
            if(lastEvaluatedKey && lastEvaluatedKey.SalesDate && lastEvaluatedKey.Id){
                const id = Number(lastEvaluatedKey["Id"]);
                const salesDate = Number(lastEvaluatedKey["SalesDate"]);
                return this.$store.dispatch("getRequest","getMoreSales&query=" +  [id, salesDate]).then(resp => {
                    if (resp && resp.statusCode === 200) {
                        const body = JSON.parse(resp.body);
                        this.$store.commit('setSales', body.Items);

                        if (body.LastEvaluatedKey && body.Items.length > 0) {
                            this.$store.commit('setSalesLastEvaluatedKey', body.LastEvaluatedKey);
                        }else{
                            this.$store.commit('setSalesLastEvaluatedKey', null);
                        }
                    }
                });
            }else{
                this.$toast.add({ severity: 'error', summary: this.$t('Info'), detail: this.$t('swal.allDataReceived'), life: 5000 });
            }
        },

        getClosedLeads(){
            return this.$store.dispatch("getRequest","getClosedLeads").then(resp => {
                if (resp && resp.statusCode === 200) {
                    const body = JSON.parse(resp.body);
                    this.$store.commit('setInitialClosedLeads', body.Items);

                    if (body.LastEvaluatedKey && body.Items.length > 0) {
                        this.$store.commit('setClosedLeadsLastEvaluatedKey', body.LastEvaluatedKey);
                    }
                }
            });
        },

        getMoreClosedLeads(){
            const lastEvaluatedKey = this.$store.getters.closedLeadsLastEvaluatedKey;
            if(lastEvaluatedKey && lastEvaluatedKey.Created && lastEvaluatedKey.Id){
                const id = Number(lastEvaluatedKey["Id"]);
                const created = Number(lastEvaluatedKey["Created"]);
                return this.$store.dispatch("getRequest","getMoreClosedLeads&query=" +  [id, created]).then(resp => {
                    if (resp && resp.statusCode === 200) {
                        const body = JSON.parse(resp.body);
                        this.$store.commit('setClosedLeads', body.Items);

                        if (body.LastEvaluatedKey && body.Items.length > 0) {
                            this.$store.commit('setClosedLeadsLastEvaluatedKey', body.LastEvaluatedKey);
                        }else{
                            this.$store.commit('setClosedLeadsLastEvaluatedKey', null);
                        }
                    }
                });
            }else{
                this.$toast.add({ severity: 'error', summary: this.$t('Info'), detail: this.$t('swal.allDataReceived'), life: 5000 });
            }
        },

        getClosedProjects(){
            return this.$store.dispatch("getRequest","getClosedProjectsNew").then(resp => {
                if (resp && resp.statusCode === 200) {
                    const body = JSON.parse(resp.body);
                    this.$store.commit('setInitialClosedProjects', body.Items);

                    if (body.LastEvaluatedKey && body.Items.length > 0) {
                        this.$store.commit('setClosedProjectsLastEvaluatedKey', body.LastEvaluatedKey);
                    }
                }
            });
        },

        getMoreClosedProjects(){
            const lastEvaluatedKey = this.$store.getters.closedProjectsLastEvaluatedKey;
            if(lastEvaluatedKey && lastEvaluatedKey.Created && lastEvaluatedKey.Id){
                const id = Number(lastEvaluatedKey["Id"]);
                const created = Number(lastEvaluatedKey["Created"]);
                return this.$store.dispatch("getRequest","getMoreClosedProjects&query=" +  [id, created]).then(resp => {
                    if (resp && resp.statusCode === 200) {
                        const body = JSON.parse(resp.body);
                        this.$store.commit('setClosedProjects', body.Items);

                        if (body.LastEvaluatedKey && body.Items.length > 0) {
                            this.$store.commit('setClosedProjectsLastEvaluatedKey', body.LastEvaluatedKey);
                        }else{
                            this.$store.commit('setClosedProjectsLastEvaluatedKey', null);
                        }
                    }
                });
            }else{
                this.$toast.add({ severity: 'error', summary: this.$t('Info'), detail: this.$t('swal.allDataReceived'), life: 5000 });
            }
        },

        getFavouriteProjects(){
            let favProjects = [];
            if (localStorage.getItem("favorisedProjects")){
                favProjects = JSON.parse(localStorage.getItem("favorisedProjects"));
                return this.$store.dispatch("getRequest", "getFavouriteProjects&query=" + favProjects).then(resp => {
                    if(resp && resp.statusCode === 200){
                        this.$store.commit('setFavorisedProjects',JSON.parse(resp.body));
                    }
                });
            }else{
                return null
            }
        },

        getEquipments(){
            return this.$store.dispatch("getRequest","getEquipments").then(resp => {
                if(resp && resp.statusCode === 200){
                    this.$store.commit('setEquipments',JSON.parse(resp.body));
                }
            });
        },

        getTasks(){
            return this.$store.dispatch("getRequest", "getTasks").then(resp => {
                if(resp && resp.statusCode === 200){
                    this.$store.commit('addTasks',JSON.parse(resp.body));
                }
            });
        },

        getUsers(){
            return this.$store.dispatch("getRequest","getUsers").then(resp => {
                if(resp && resp.statusCode === 200){
                    this.$store.commit('setResponsibles',JSON.parse(resp.body));
                }
            });
        },

        getOffers(){
            return this.$store.dispatch("getRequest","getOffers").then(resp => {
                if(resp && resp.statusCode === 200) {
                    this.$store.commit('setOffers', JSON.parse(resp.body));
                }
            });
        },

        getCustomLists(){
            return this.$store.dispatch("getRequest","getCustomLists").then(resp => {
                if(resp && resp.statusCode === 200) {
                    this.$store.commit('setCustomLists', JSON.parse(resp.body));
                    this.$store.commit('setAllCustomLists', JSON.parse(resp.body));
                }
            });
        },

        getTagsContainers(){
            return this.$store.dispatch("getRequest","getTagsContainers").then(resp => {
                if(resp && resp.statusCode === 200) {
                    this.$store.commit('setTagContainers', JSON.parse(resp.body));
                    this.$store.commit('setAllTagContainers', JSON.parse(resp.body));
                }
            });
        }
    }
}