<template>
  <MaterialTable :data-key="'AmountPaid'" :table-id="'salesPayments'" :data-prop="payments" :columns="columns"
                 :has-paginator="true" :rows-count="10" :has-global-filter="true" :show-toggle-column="true"
                 :filter-prop="filter" :filter-options="filterOptions" :sortable="true" :has-removable-sort="true"
                 :key="tableKey" :selection-mode="'single'" :resizable-columns="true"/>

</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";
export default {
  name: "SalesPaymentsTable",
  components: {MaterialTable},
  props:{
    payments:Array,
  },
  data(){
    return {
      columns:[
        { field: 'AmountPaid', header: this.$t('amountPaid') },
        { field: 'PaymentDateUnix', header: this.$t('paymentDate') },
        { field: 'MissingAmount', header: this.$t('missingAmount') },
        { field: 'PaymentDeadlineUnix', header: this.$t('paymentDeadline') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        AmountPaid: { value: null, matchMode: FilterMatchMode.CONTAINS },
        PaymentDateUnix: { value: null, matchMode: FilterMatchMode.CONTAINS },
        MissingAmount: { value: null, matchMode: FilterMatchMode.CONTAINS },
        PaymentDeadlineUnix: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Title', 'AmountPaid', 'MissingAmount'],
      tableKey:0,
    }
  }
}
</script>

<style scoped>

</style>