<template>
  <div>
    <CustomLabel class="w-full" label="Status" :input="offerStatus">
      <Dropdown class="w-full" v-model="offerStatus" :options="offerStatusOptions"
                :class="v$.offerStatus.$error ? 'p-invalid' : ''" optionLabel="text" placeholder="Status"/>
    </CustomLabel>
  </div>
</template>

<script>
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import Dropdown from "primevue/dropdown";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
  name: "CloseOffer",
  components:{CustomLabel, Dropdown},
  emits: ['closeOffer'],
  data(){
    return {
      offerStatus:null,
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      offerStatus: {required}
    }
  },

  computed:{
    offerStatusOptions(){
      return [
        {text:this.$t('sold'), value:'20'},
        {text:this.$t('rejected'), value:'30'},
        {text:this.$t('replaced'), value:'40'},
        {text:this.$t('lost'), value:'50'},
        {text:this.$t('delayed'), value:'60'},
      ];
    }
  },
  methods:{
    emitCloseOffer(){
      this.v$.$validate();
      if(!this.v$.$error) {
        this.$emit('close-offer', this.offerStatus.value);
      }
    }
  }
}
</script>

<style scoped>

</style>